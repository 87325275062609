import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ArrayUtil, InputValue, LabeledValue, ObjectUtil, SelectionData, StringUtil } from '@kfd/core';
import { TooltipModule } from 'primeng/tooltip';
import { CheckboxModule } from 'primeng/checkbox';
import { FormsModule } from '@angular/forms';

interface InlineSelectOption {
  id: string;
  label: string;
  selected: boolean;
  value: unknown;
  image?: string;
  description?: string;
  additionalProperties?: {
    label: string;
    value: string;
  }[];
}

export interface InlineSelectConfig {
  direction: 'row' | 'col';
  minSelections: number;
  maxSelections: number;
  optionIdPath: string;
  optionLabelPath?: string | undefined;
  optionDescriptionPath?: string | undefined;
  optionValuePath?: string | undefined;
  optionImagePath?: string | undefined;
}

@Component({
  selector: 'kfd-web-inline-selection',
  imports: [CommonModule, TooltipModule, CheckboxModule, FormsModule],
  providers: [],
  templateUrl: './inline-selection.component.html',
  styleUrl: './inline-selection.component.scss',
})
export class InlineSelectionComponent {
  @Input()
  public columns = 1;
  @Output()
  public selectionChange = new EventEmitter<string[]>();
  // internally used values
  protected inlineSelectOptions: InlineSelectOption[] | undefined;
  protected maxSelectionsReached = false;
  private _selectedIds: string[] = [];

  private _config!: InlineSelectConfig;

  get config(): InlineSelectConfig {
    return this._config;
  }

  @Input({ required: true })
  set config(value: InlineSelectConfig) {
    this._config = value;
    this.buildInlineSelectOptions();
  }

  private _additionalValues: LabeledValue[] | undefined = [];

  get additionalValues(): LabeledValue[] | undefined {
    return this._additionalValues;
  }

  @Input()
  set additionalValues(value: LabeledValue[] | undefined) {
    this._additionalValues = value;
    this.buildInlineSelectOptions();
  }

  // external values
  private _options: SelectionData[] | undefined;

  get options(): SelectionData[] | undefined {
    return this._options;
  }

  @Input()
  set options(values: SelectionData[] | undefined) {
    if (!values) {
      this._options = [];
      return;
    }
    this._options = values;
    this.buildInlineSelectOptions();
  }

  public get selection(): string[] {
    // return this.inlineSelectOptions?.filter((option) => option.selected) ?? [];
    return this._selectedIds;
  }

  @Input()
  public set selection(values: string[]) {
    this._selectedIds = values;
    this.buildInlineSelectOptions();
  }

  protected toggleSelection(option: InlineSelectOption, selected: boolean): void {
    if (selected) {
      this.selectOption(option);
    } else {
      this.deSelectOption(option);
    }
  }

  protected selectOption(option: InlineSelectOption): void {
    //skip if max selections reached (but only for multiselect)
    if (this.maxSelectionsReached) {
      return;
    }

    if (this.config.maxSelections && this.config.maxSelections > 1) {
      this._selectedIds = ArrayUtil.distinct([...this._selectedIds, option.id]);
    } else {
      this._selectedIds = [option.id];
    }
    this.checkMaxSelections();
    this.updateSelections();
    this.emitChanges();
  }

  protected deSelectOption(option: InlineSelectOption): void {
    if (this._selectedIds.includes(option.id)) {
      this._selectedIds = this._selectedIds.filter((o) => o !== option.id);
    }
    this.updateSelections();
    this.checkMaxSelections();
    this.emitChanges();
  }

  protected updateSelections(): void {
    this.inlineSelectOptions = this.inlineSelectOptions?.map((o) => {
      return {
        ...o,
        selected: this._selectedIds.includes(o.id),
      };
    });
  }

  protected emitChanges(): void {
    this.selectionChange.emit(this.selection);
  }

  private checkMaxSelections(): void {
    const maxSelections = this.config.maxSelections ?? 0;
    //do not set for single selections (maxSelection = 1), to allow change without deselection
    this.maxSelectionsReached = maxSelections > 1 && this._selectedIds.length >= maxSelections;
  }

  private buildInlineSelectOptions(): void {
    if (!this.options || !this.config) {
      this.inlineSelectOptions = [];
      return;
    }
    this.inlineSelectOptions = this.options.map((option) => {
      const label = ObjectUtil.getValue(option, this.config.optionLabelPath);
      const description = StringUtil.ellipsis(ObjectUtil.getValue(option, this.config.optionDescriptionPath), 80);
      const image = ObjectUtil.getValue(option, this.config.optionImagePath);
      const additionalProperties =
        this._additionalValues
          ?.map((additionalValue) => ({
            label: additionalValue.label,
            value: this.findAdditionalValue(additionalValue.value, option.values),
            // value: '' + (option.values?.find((optionValue) => optionValue.identifier === additionalValue.value)?.value ?? ''),
          }))
          .filter((v) => !!v.value) ?? [];
      return {
        id: ObjectUtil.getValue(option, this.config.optionIdPath) as string,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        label: typeof label === 'string' ? (label as string) : '',
        description,
        image: typeof image === 'string' ? (image as string) : undefined,
        selected: false,
        value: option,
        additionalProperties,
      };
    });
    // console.log(this.options, this.inlineSelectOptions)
    this.updateSelections();
  }

  private findAdditionalValue(identifier: string, inputValues: InputValue[] | undefined): string {
    if (!inputValues || !inputValues.length) {
      return '';
    }
    const inputValue = inputValues.find((optionValue) => optionValue.identifier === identifier);
    // console.log(inputValue);
    return '' + inputValue?.value;
  }
}
