export enum DBDocumentType {
  ASSET = 'assets',
  BLOB = 'blobs',
  PROJECT = 'projects',
  CONFIGURATION = 'configurations',
  PUBLISHED_CFG = 'publishedConfigurations',
  CUSTOMER_REQUEST = 'customerRequests',
  BASE_DATA = 'baseData',
  NOTIFICATION = 'notifications',
  USER_SETTING = 'userSettings',
}

export function isDbDocumentType(key: string): key is DBDocumentType {
  return Object.values(DBDocumentType).includes(key as unknown as DBDocumentType);
}

export interface InvalidDocuments {
  invalidIds: string[];
  documentCount: number;
}

export type InvalidDocumentList = Record<DBDocumentType, InvalidDocuments>;
