import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ObjectUtil, StringUtil } from '@kfd/core';

export enum INPUT_TYPES {
  STRING = 'string',
  SAVE_STRING = 'save_string',
  SAVE_STRING_UPPERCASE = 'save_string_uc',
  DOMAIN = 'domain',
  EMAIL = 'email',
}

/**
 * @deprecated use toggle-input-field instead
 */
@Component({
  selector: 'kfd-two-step-input',
  templateUrl: './two-step-input.component.html',
  styleUrls: ['./two-step-input.component.scss'],
  standalone: false,
})
export class TwoStepInputComponent {
  @Output() valueChange: EventEmitter<string> = new EventEmitter<string>();
  @Output() editModeChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Input()
  hint: string | undefined;

  @Input()
  inputType: INPUT_TYPES | string = INPUT_TYPES.STRING;

  @Input()
  icon: string | undefined;

  @Input()
  editMode = false;

  @Input()
  maxLength = 9999;

  inputId: string | undefined = 'input-' + new Date().getTime();
  private _originValue: string | undefined;

  _value: string | undefined;

  get value(): string | undefined {
    return this._value;
  }

  @Input()
  set value(value: string | undefined) {
    if (!value) {
      return;
    }
    this._value = ObjectUtil.clone(value);
    this._originValue = ObjectUtil.clone(value);
  }

  validateValue() {
    if (!this._value) {
      return;
    }
    switch (this.inputType) {
      case INPUT_TYPES.SAVE_STRING:
        this._value = StringUtil.toSaveString(this._value);
        break;
      case INPUT_TYPES.SAVE_STRING_UPPERCASE:
        this._value = StringUtil.toSaveString(this._value).toUpperCase();
        break;
      case INPUT_TYPES.DOMAIN:
        this._value = StringUtil.toDomainName(this._value);
        break;
      case INPUT_TYPES.EMAIL:
        this._value = StringUtil.toEmail(this._value);
        break;
    }
  }

  changeEditMode(mode: boolean) {
    if (this.editMode === mode) {
      return;
    }
    this.editMode = mode;
    this.editModeChange.emit(mode);
  }

  updateValue() {
    this.validateValue();
    this.changeEditMode(false);
    if (this._value !== this._originValue) {
      this.valueChange.emit(this._value);
    }
  }
}
