import { Pipe, PipeTransform } from '@angular/core';
import { isObservable, Observable, of } from 'rxjs';
import { catchError, map, startWith } from 'rxjs/operators';

export interface LoadingWrapper<T> {
  value?: T;
  loading: boolean;
  error?: boolean;
}

@Pipe({
  name: 'loading',
  standalone: false,
})
export class LoadingPipe<T = unknown> implements PipeTransform {
  transform(val: Observable<T> | undefined): Observable<LoadingWrapper<T>> {
    return loadingWrapper<T>(val);
  }
}

export function loadingWrapper<T>(obs: Observable<T> | undefined): Observable<LoadingWrapper<T>> {
  if (obs === undefined) {
    return of({
      value: undefined,
      loading: true,
    });
  }
  return isObservable(obs)
    ? (obs.pipe(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        map((value: any) => ({ loading: false, value })),
        startWith({ loading: true }),
        catchError((error) => of({ loading: false, error })),
      ) as Observable<{ value?: T; loading: boolean }>)
    : of({
        value: obs,
        loading: false,
      });
}
