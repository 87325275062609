import { ChildWrapper, ConditionValidator, Configuration, ConfiguratorPage, Page } from '@kfd/core';
import { BehaviorSubject } from 'rxjs';
import { ConfigurationService } from './configuration.service';
import { ConfigurationStateService } from './configuration-state.service';
import { Injectable } from '@angular/core';
import { CfgException } from '../../common/cfg-exception';

export interface PageInfo {
  currentPage: Page | undefined;
  hasPrev: boolean;
  hasNext: boolean;
}

//injected in configuration.component

@Injectable()
export class PaginationService {
  public pageInfo$ = new BehaviorSubject<PageInfo | undefined>(undefined);
  protected lastPage: Page | undefined;
  protected currentPage: Page | undefined;
  private _configuration: Configuration | undefined;

  constructor(
    private readonly configurationService: ConfigurationService,
    private readonly configurationStateService: ConfigurationStateService,
  ) {
    configurationService.onCfgChange().subscribe((cfgUtil) => {
      this._configuration = cfgUtil.getCfg();
    });
  }

  private _hasPrev = false;

  get hasPrev(): boolean {
    if (this.currentPage?.noNavPrev === true) {
      return false;
    }
    return this._hasPrev;
  }

  set hasPrev(value: boolean) {
    this._hasPrev = value;
  }

  private _hasNext = false;

  get hasNext(): boolean {
    if (this.currentPage?.noNavNext === true) {
      return false;
    }
    return this._hasNext;
  }

  set hasNext(value: boolean) {
    this._hasNext = value;
  }

  onPageChange() {
    if (!this.currentPage) {
      this.setNoPage();
      return;
    }

    const conditions = this.configurationService.cfgUtil.getEntryConditionGroups(this.currentPage.name);
    const fieldNames = this.configurationService.cfgUtil.getFieldNames();
    const valueMap = this.configurationStateService.getValueMap(fieldNames);
    const isRelevant = ConditionValidator.evaluateGroups(conditions, valueMap);
    if (isRelevant === false) {
      this.skip();
      return;
    }

    this.pageInfo$.next({
      currentPage: this.currentPage,
      hasPrev: this.hasPrev,
      hasNext: this.hasNext,
    });
  }

  /**
   * opens the next or previous page
   * depending on last navigation direction
   */
  skip() {
    if (!this.getPages().length) {
      return;
    }
    if (!this.currentPage || !this.lastPage) {
      this.next();
    }

    for (const item of this.getPages()) {
      if (item.entry?.name === this.currentPage?.name) {
        this.previous();
        return;
      }
      if (item.entry?.name === this.lastPage?.name) {
        this.next();
        return;
      }
    }
  }

  previous() {
    if (!this.getPages().length || !this.currentPage) {
      this.setNoPage();
      return;
    }
    const children = this.getPages();
    const currentIndex = this.getIndexByName(this.currentPage.name, children);
    if (currentIndex === 0) {
      this._hasPrev = false;
      return;
    }
    if (this.currentPage) {
      this.lastPage = this.currentPage;
    }
    this.currentPage = children[currentIndex - 1].entry;
    this._hasNext = true;
    this._hasPrev = currentIndex - 1 > 0;
    this.onPageChange();
  }

  next() {
    if (!this.getPages().length) {
      this.setNoPage();
      return;
    }
    const children = this.getPages();
    if (!this.currentPage) {
      this.currentPage = children[0].entry;
      this._hasNext = children.length > 1;
      this.onPageChange();
      return;
    }

    const next = this.getNextIndex(this.currentPage.name, children);
    if (next === 0) {
      this._hasNext = false;
      return;
    }
    if (this.currentPage) {
      this.lastPage = this.currentPage;
    }
    this.currentPage = children[next].entry;
    this._hasNext = this.getNextIndex(this.currentPage.name, children) > 0;
    this._hasPrev = next > 0;
    this.onPageChange();
  }

  goToIndex(index: number): void {
    if (!this.getPages().length) {
      this.setNoPage();
      return;
    }
    const children = this.getPages();
    if (!children[index]) {
      throw new CfgException(`Page index ${index} does not exist`);
    }
    this.currentPage = children[index].entry;
    this._hasNext = this.getNextIndex(this.currentPage.name, children) > 0;
    this._hasPrev = false;
    this.onPageChange();
  }

  goToPage(name: string): void {
    if (!this.getPages().length) {
      return;
    }
    const currentIndex = this.getIndexByName(name, this.getPages());
    if (currentIndex === -1) {
      throw new CfgException(`Page with name ${name} does not exist`);
    }
    this.goToIndex(currentIndex);
  }

  getNextIndex(currentPageName: string, items: ChildWrapper[]): number {
    const currentIndex = this.getIndexByName(currentPageName, items);
    if (currentIndex === -1) {
      return 0;
    }

    const next = currentIndex + 1;
    if (items.length > next) {
      return next;
    }

    return 0;
  }

  getIndexByName(currentPageName: string, items: ChildWrapper[]): number {
    if (!items) {
      return -1;
    }

    for (const [i, item] of items.entries()) {
      if (item.entry?.name === currentPageName) {
        return i;
      }
    }
    return -1;
  }

  private getPages(): ConfiguratorPage[] {
    if (!this._configuration) {
      return [];
    }
    return this._configuration.children.filter((configuratorPage) => configuratorPage.entry.hide !== true);
  }

  private setNoPage() {
    this.currentPage = undefined;
    this.pageInfo$.next({
      currentPage: undefined,
      hasPrev: false,
      hasNext: false,
    });
  }
}
