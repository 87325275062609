import { Component, Inject, Input, OnInit } from '@angular/core';
import { catchError, Observable, of } from 'rxjs';
import { ContentService } from '../../services/content.service';
import { App, ICON, WebPage } from '@kfd/core';
import { ButtonModule } from 'primeng/button';
import { CommonModule } from '@angular/common';
import { PageContentComponent } from '../page-content/page-content.component';
import { DialogModule } from 'primeng/dialog';
import { Tooltip } from 'primeng/tooltip';
import { LoaderComponent } from '../loader/loader.component';
import { ConfigService, WEB_CONFIG_SERVICE } from '../../services';

@Component({
  selector: 'kfd-docu-btn',
  templateUrl: './docu-btn.component.html',
  styleUrls: ['./docu-btn.component.scss'],
  standalone: true,
  imports: [ButtonModule, CommonModule, DialogModule, PageContentComponent, Tooltip, LoaderComponent],
})
export class DocuBtnComponent implements OnInit {
  @Input()
  public pageId: string | undefined;

  @Input()
  public sectionId: string | undefined;

  @Input()
  public buttonType: 'text' | 'round' = 'round';

  protected webApp = '';
  protected webPage$: Observable<WebPage> | undefined;
  protected readonly ICON = ICON;

  protected showDialog = false;

  constructor(
    private contentService: ContentService,
    @Inject(WEB_CONFIG_SERVICE) readonly configService: ConfigService,
  ) {
    this.webApp = configService.getApp(App.WEB);
  }

  ngOnInit(): void {
    if (this.pageId) {
      this.webPage$ = this.contentService.getDocuPage(this.pageId).pipe(
        catchError(() => {
          return of({
            title: 'Ups',
            content: [
              {
                id: this.sectionId,
                type: 'html',
                data: `Den Inhalt konnten wir leider nicht finden.<br><br>
                     <a href="${this.webApp}/support/documentation" target="_blank">
                       > Zur Dokumentation
                     </a>`,
              },
            ],
          } as WebPage);
        }),
      );
    }
  }
}
