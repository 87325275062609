import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Inject,
  Input,
  Output,
} from '@angular/core';
import {
  BaseDataRef,
  Create,
  EmptyFormValue,
  InputValue,
  Is,
  ListUtil,
  MultiSelectFieldConfig,
  MultiSelectionFormValue,
  ObjectUtil,
  SELECT_FIELD_LAYOUTS,
  SelectFieldConfig,
  SelectionData,
  SingleSelectionFormValue,
} from '@kfd/core';
import { DATA_PROVIDER, DataProvider } from '../../service/data-provider';
import { Observable, of, tap, toArray } from 'rxjs';
import { CFG_CONTEXT_SERVICE, CfgContextService } from '../../service/cfg-context.service';
import { CfgSettingsService, ScreenSize } from '../../service/cfg-settings.service';
import { OverlayOptions } from 'primeng/api';
import { CfgException } from '../../../common/cfg-exception';
import { map } from 'rxjs/operators';

@Component({
  selector: 'kfd-configurator-field-select',
  templateUrl: './configurator-field-select.component.html',
  styleUrls: ['./configurator-field-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class ConfiguratorFieldSelectComponent {
  @Output()
  public valueChange: EventEmitter<MultiSelectionFormValue | SingleSelectionFormValue | EmptyFormValue> =
    new EventEmitter();
  protected selection: string[] = [];
  protected layouts = SELECT_FIELD_LAYOUTS;
  protected selectionDataList$: Observable<SelectionData[]> | undefined;
  protected singleSelection = true;
  protected loading = false;
  protected readonly overlayOptions: OverlayOptions;

  protected screenSize$: Observable<ScreenSize>;
  protected readonly toArray = toArray;
  protected emptyName = '--empty--';
  protected appendTo: 'body' | undefined;
  protected readonly ScreenSize = ScreenSize;
  private _fieldConfig: SelectFieldConfig | MultiSelectFieldConfig | undefined;

  constructor(
    private ref: ChangeDetectorRef,
    @Inject(CFG_CONTEXT_SERVICE) private ctx: CfgContextService,
    private readonly cfgSettingsService: CfgSettingsService,
    @Inject(DATA_PROVIDER) private dataProvider: DataProvider,
  ) {
    this.screenSize$ = cfgSettingsService.onScreenSizeChange();
    this.appendTo = cfgSettingsService.embedded ? 'body' : undefined;

    this.overlayOptions = {
      baseZIndex: 1000,
      autoZIndex: true,
      hideOnEscape: true,
      //todo switch to modal on mobile devices
      // mode: 'modal',
      appendTo: this.appendTo,
    };
  }

  get field(): SelectFieldConfig | MultiSelectFieldConfig | undefined {
    return this._fieldConfig;
  }

  @Input()
  set field(fieldConfig: SelectFieldConfig | MultiSelectFieldConfig | undefined) {
    if (!fieldConfig) {
      return;
    }

    this._fieldConfig = ObjectUtil.clone(fieldConfig);

    this.singleSelection = !Is.multiSelectFieldConfig(fieldConfig);

    this.updateData().then(() => {
      this.loading = false;
      this.ref.markForCheck();
    });
  }

  @Input()
  public set value(selectionFormValue: MultiSelectionFormValue | SingleSelectionFormValue | undefined) {
    if (Is.singleSelectionFormValue(selectionFormValue)) {
      this.selection = selectionFormValue.selection ? [selectionFormValue.selection.name] : [];
    }
    if (Is.multiSelectionFormValue(selectionFormValue)) {
      if (selectionFormValue?.selection.length === 0) {
        this.selection = [];
        return;
      }
      this.selection = selectionFormValue.selection.map((selectionData) => selectionData.name);
    }
  }

  protected onSelectionChange(selectionData: SelectionData[], values: string | string[]) {
    const selectionValues = Array.isArray(values) ? values : [values];

    this.selection = selectionValues.filter((value) => value !== this.emptyName);

    if (this.selection.length === 0) {
      this.valueChange.emit(Create.emptyFormValue());
      return;
    }

    if (this.singleSelection) {
      const selectionName = this.selection[0];
      const selectionList = selectionData.find((data) => data.name === selectionName);
      if (selectionList) {
        this.valueChange.emit(Create.singleSelectionFormValue(selectionList));
      } else {
        this.valueChange.emit(Create.emptyFormValue());
      }
    } else {
      if (this.selection.length > 0) {
        const selectionValues: SelectionData[] = ListUtil.filterEmpty<SelectionData>(
          this.selection.map((selectionName) => selectionData.find((data) => data.name === selectionName)),
        );
        this.valueChange.emit(Create.multiSelectionFormValue(selectionValues));
      } else {
        this.valueChange.emit(Create.emptyFormValue());
      }
    }
  }

  protected async updateData() {
    if (!this.field?.dataHandler) {
      return;
    }
    this.loading = true;
    const dataHandler = this.field.dataHandler;

    const selectionList = Is.listDataHandler(dataHandler)
      ? this.handleSimpleListData(dataHandler.data)
      : Is.dynamicDataHandler(dataHandler)
        ? this.dataProvider.getData(this.ctx.projectId, this.ctx.configuratorId, {
            templateName: dataHandler.templateName,
            tags: dataHandler.tags,
            sort: dataHandler.sort,
          })
        : undefined;

    if (!selectionList) {
      throw new CfgException('Unsupported data handler');
    }

    this.selectionDataList$ = selectionList.pipe(
      map((selectionData) => {
        if (this.field?.emptySelection) {
          // add empty selection option
          return [Create.selectionData(this.emptyName, this.field.emptySelection), ...selectionData];
        }
        return selectionData;
      }),
      tap((selectionData: SelectionData[]) => {
        if (this.field?.required) {
          //set first option as selection
          if (this.selection.length === 0 && selectionData.length > 0) {
            this.onSelectionChange(selectionData, selectionData[0].name);
          }
        }
      }),
    );
  }

  protected getMin(): number {
    return Is.multiSelectFieldConfig(this._fieldConfig) ? (this._fieldConfig?.min?.value ?? 1) : 1;
  }

  protected getMax(): number {
    return Is.multiSelectFieldConfig(this._fieldConfig) ? (this._fieldConfig?.max?.value ?? 1) : 1;
  }

  protected findOptionValue(values: InputValue[], valueName: string): string {
    const value = values.find((value) => value.identifier === valueName);
    return value ? '' + value.value : '';
  }

  private handleSimpleListData(dataEntries: (SelectionData | BaseDataRef)[]): Observable<SelectionData[]> {
    if (!dataEntries || dataEntries.length === 0) {
      return of([]);
    }
    return this.dataProvider
      .getDataByIdentifier(
        this.ctx.projectId,
        this.ctx.configuratorId,
        dataEntries.map((dataRef) => dataRef.name),
      )
      .pipe(
        map((resultList) =>
          dataEntries
            .map((dataEntry) => {
              if (Is.selectionData(dataEntry)) {
                return dataEntry;
              }
              return resultList.find((resultListValue) => resultListValue.name === dataEntry.name);
            })
            .filter((v) => !!v),
        ),
      );
  }
}
