import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ICON } from '@kfd/core';

@Component({
  selector: 'kfd-placeholder-msg',
  templateUrl: './placeholder-message.component.html',
  styleUrls: ['./placeholder-message.component.scss'],
  standalone: false,
})
export class PlaceholderMessageComponent {
  @Output() btnClick = new EventEmitter<void>();

  @Input()
  infoTitle = '';

  @Input()
  infoText = '';

  @Input()
  infoIcon = ICON.INFO;

  @Input()
  infoIconVisible = true;

  @Input()
  btnIcon = ICON.ADD;

  @Input()
  btnVisible = false;

  @Input()
  btnDisabled = false;

  @Input()
  btnLabel = '';

  @Input()
  btnLink: string | undefined;

  @Input()
  docuPageId: string | undefined;

  @Input()
  docuSectionId: string | undefined;
}
