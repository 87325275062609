import { Observable, of, share, tap } from 'rxjs';
import { Cache, CacheOptions, unvInterval, UnvTimeoutValue } from '@kfd/core';
import { take } from 'rxjs/operators';

export interface FeCacheOptions extends CacheOptions {
  autoClean?: boolean;
  cleanInterval?: number;
}

export class FeCache extends Cache {
  private obs$ = new Map<string, Observable<unknown>>();
  private readonly autoCleanInterval: UnvTimeoutValue | undefined;

  /**
   *
   * @param autoClean
   * @param cleanInterval in seconds
   * @param defaultTtl in seconds
   */
  constructor({ autoClean = false, cleanInterval = 120, defaultTtl = 0 }: FeCacheOptions = {}) {
    super({
      defaultTtl,
    });
    if (autoClean === true) {
      this.autoCleanInterval = unvInterval(this.clean.bind(this), cleanInterval * 1000);
    }
  }

  public static key(...values: string[]): string {
    return values.join('-');
  }

  override destruct() {
    super.destruct();
    if (this.autoCleanInterval) {
      clearInterval(this.autoCleanInterval);
    }
  }

  /**
   * caches the observable and the result which avoids duplicate requests
   */
  cacheObs<T = unknown>(obs: Observable<T>, key: string, ttl: number | undefined = undefined): Observable<T> {
    const obs$: Observable<T> = obs.pipe(
      share<T>(),
      tap((res) => {
        this.set(key, res, ttl);
      }),
    );

    // if the key is already in the cache, return the cached value
    if (this.has(key)) {
      // return observable with exactly one result
      return of(this.get<T>(key)).pipe(take(1));
    }

    // if there is a running observable, return it
    if (this.obs$.has(key)) {
      return this.obs$.get(key) as Observable<T>;
    }

    this.obs$.set(key, obs$);
    return obs$;
  }
}
