import { Component, Input } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { ICON } from '@kfd/core';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { Observable } from 'rxjs';

@Component({
  selector: 'kfd-btn-menu',
  templateUrl: './btn-menu.component.html',
  styleUrl: './btn-menu.component.scss',
  standalone: false,
})
export class BtnMenuComponent {
  @Input()
  public items: MenuItem[] = [];
  @Input()
  public endSeparator = false;
  protected ICON = ICON;
  protected isMinTablet$: Observable<BreakpointState>;

  constructor(private readonly breakpointObserver: BreakpointObserver) {
    this.isMinTablet$ = this.breakpointObserver.observe('(min-width: 768px)');
  }
}
