import { Component, DoCheck, EventEmitter, Input, Output } from '@angular/core';
import { ObjectUtil } from '@kfd/core';

@Component({
  selector: 'kfd-toggle-setting',
  templateUrl: './toggle-setting.component.html',
  styleUrls: ['./toggle-setting.component.scss'],
  standalone: false,
})
export class ToggleSettingComponent implements DoCheck {
  @Output()
  public enabledChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output()
  public disable: EventEmitter<void> = new EventEmitter<void>();
  @Output()
  public enable: EventEmitter<void> = new EventEmitter<void>();
  @Input()
  public property: string | undefined;
  @Input()
  public offLabel = '';
  @Input()
  public onLabel = '';
  @Input()
  public label = '';
  public enabled = false;

  @Input()
  public trackObjectChanges = true;

  private _object: Record<string, unknown> | object | undefined;

  public get object(): Record<string, unknown> | object | undefined {
    return this._object;
  }

  @Input()
  public set object(value: Record<string, unknown> | object | undefined) {
    //we do not clone the object to keep ngDoCheck working, but we do not modify the object
    this._object = value;
    this.checkEnabledState();
  }

  public ngDoCheck(): void {
    if (this.trackObjectChanges) {
      //validate on every object change
      this.checkEnabledState();
    }
  }

  protected onEnabledChange(e: { checked: boolean }): void {
    if (e.checked) {
      this.enable.emit();
    } else {
      this.disable.emit();
    }

    // console.log(e.checked);
    if (!this.trackObjectChanges) {
      this.enabled = e.checked;
    }
  }

  private checkEnabledState(): void {
    if (this._object === undefined || this.property === undefined) {
      return;
    }
    const enabled = ObjectUtil.getValue(this._object, this.property) !== undefined;
    if (this.enabled !== enabled) {
      this.enabledChange.emit(enabled);
      this.enabled = enabled;
    }
  }
}
