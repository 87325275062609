import { Injectable, Pipe, PipeTransform } from '@angular/core';

/**
 * returns a flatten array of values from an array of objects
 */
@Pipe({
  name: 'singleValue',
  standalone: false,
})
@Injectable()
export class SingleValuePipe implements PipeTransform {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  transform(items: any[], key: string): string[] {
    if (!items) {
      return [];
    }
    return items.map((item) => item[key]);
  }
}
