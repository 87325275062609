import { Component, Input } from '@angular/core';
import { ICON, LabeledValue, SelectOption } from '@kfd/core';
import { ControlValueAccessor, FormBuilder, FormsModule, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ButtonModule } from 'primeng/button';
import { TooltipModule } from 'primeng/tooltip';
import { InputTextModule } from 'primeng/inputtext';
import { PaginatorModule } from 'primeng/paginator';
import { SelectModule } from 'primeng/select';

@Component({
  selector: 'kfd-label-value-input',
  imports: [ButtonModule, TooltipModule, InputTextModule, PaginatorModule, FormsModule, SelectModule],
  templateUrl: './label-value-input.component.html',
  styleUrl: './label-value-input.component.css',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: LabelValueInputComponent,
    },
  ],
})
export class LabelValueInputComponent<T> implements ControlValueAccessor {
  protected readonly ICON = ICON;

  protected onChanged: CallableFunction | undefined;
  protected onTouched: CallableFunction | undefined;

  protected disabled = false;
  protected valueToEdit = '';
  protected values: LabeledValue[] = [];

  constructor(private readonly formBuilder: FormBuilder) {
    // this.updateForm();
  }

  private _valueOptions: SelectOption<T>[] = [];

  public get valueOptions(): SelectOption<T>[] | undefined {
    return this._valueOptions;
  }

  @Input()
  public set valueOptions(values: SelectOption<T>[] | undefined) {
    if (!Array.isArray(values)) {
      values = [];
    }
    this._valueOptions = [
      {
        label: '---',
        identifier: '',
        value: null,
      },
      ...values,
    ];
  }

  registerOnChange(onChange: CallableFunction): void {
    this.onChanged = onChange;
  }

  registerOnTouched(onChange: CallableFunction): void {
    this.onTouched = onChange;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  writeValue(labeledValues: LabeledValue[] | undefined): void {
    if (!labeledValues) {
      return;
    }
    this.values = labeledValues;
  }

  protected valueLabel(value: LabeledValue): string {
    const option = this.valueOptions?.find((valueOption) => valueOption.identifier === value?.value);
    return option ? option.label : '---';
  }

  protected editValue(value: string): void {
    this.valueToEdit = value;
  }

  protected cancelEdit(): void {
    this.removeValue('');
    this.valueToEdit = '';
  }

  protected updateValue(label: string, value: string): void {
    if (!value || !label) {
      return;
    }
    if (value !== this.valueToEdit) {
      const valueExists = this.values.find((v) => v.value === value);
      if (valueExists) {
        return;
      }
    }
    this.values = this.values.map((v) => (v.value === this.valueToEdit ? { label, value } : v));
    this.emitValues();
    this.cancelEdit();
  }

  protected addValue(): void {
    if (this.disabled || this.values.find((v) => v.value === '')) {
      return;
    }
    this.values.push({ label: 'Neu', value: '' });
    this.editValue('');
  }

  protected removeValue(value: string): void {
    if (this.disabled) {
      return;
    }
    this.values = this.values.filter((v) => v.value !== value);
    this.valueToEdit = '';
    this.emitValues();
  }

  protected emitValues(): void {
    if (this.onChanged) {
      //filter duplicates
      const values = this.values.filter((v, i, a) => a.findIndex((t) => t.value === v.value) === i);
      this.onChanged(values.filter((v) => v.value !== ''));
    }
  }
}
