<!--<ng-container *ngIf="validationErrors">-->
<!--  <ng-container *ngFor="let error of validationErrors | keyvalue">-->
<!--  </ng-container>-->
<!--</ng-container>-->
<div *ngIf="field" class="p-fluid flex flex-column">
  <ng-container *ngIf="submitted === false">
    <p-message [text]="error" severity="error"></p-message>
    <div class="mt-2">
      <p-message
        severity="info"
        text="Bitte laden Sie neu oder versuchen Sie es zu einem späteren Zeitpunkt erneut."
      ></p-message>
    </div>
    <button
      (click)="refresh()"
      [disabled]="demoMode === true"
      class="full-width mt-2"
      icon="pi {{ CFG_ICON.CHECK }}"
      label="Neu laden"
      pButton
      type="button"
    ></button>
  </ng-container>
  <ng-container *ngIf="submitted === true">
    <p-message [text]="field.successText" severity="success"></p-message>
    <div class="mt-3 flex-1">
      <div *ngIf="field.showRequestId">
        <div>
          Anfrage-Nummer:
          <span data-automationId="request-code">{{ requestCode }}</span>
        </div>
        <div *ngIf="requestLink && field.linkRequestId" class="mt-2">
          <a
            [attr.href]="!demoMode ? requestLink : undefined"
            [target]="configService.embedded ? '_blank' : '_self'"
            data-automationId="show-request-btn"
          >
            <button icon="pi {{ CFG_ICON.INFO }}" label="Anfrage anzeigen" pButton type="button"></button>
          </a>
        </div>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="submitted === undefined">
    <kfd-validation-error [validationErrors]="validationResults"></kfd-validation-error>

    @if (!skipPrivacy) {
      <div class="flex gap-2">
        <p-checkbox
          (onChange)="privacyHint = false"
          [(ngModel)]="privacyStmt"
          [binary]="true"
          [ngClass]="{ 'ng-invalid': privacyHint }"
          class="ng-dirty mt-2"
          data-automationId="accept-privacy-checkbox"
          inputId="privacyStmt"
        ></p-checkbox>
        <label for="privacyStmt">Ich habe die Datenschutzerklärung gelesen und bin mit der Verarbeitung
          einverstanden.</label>
      </div>

      <p-button
        (click)="privacyPanel.toggle($event)"
        [outlined]="true"
        class="mt-1 ml-5"
        label="Datenschutzerklärung ansehen"
        size="small"
      ></p-button>
      <p-overlayPanel #privacyPanel>
        <strong>Verarbeitung personenbezogener Daten im Auftrag</strong>
        <p>
          Ihre personenbezogenen Daten werden von uns (konfidoo) im Auftrag verarbeitet. Dies bedeutet, dass wir als
          Dienstleister gemäß den geltenden Datenschutzbestimmungen (insbesondere der Datenschutz-Grundverordnung,
          DSGVO) verpflichtet sind, Ihre Daten ausschließlich im Rahmen der Dienstleistungen zu verarbeiten.
        </p>

        Wir verarbeiten Ihre Daten ausschließlich zu folgenden Zwecken:
        <ul>
          <li>Bereitstellung und Betrieb der Dienstleistungen</li>
          <li>Erfüllung rechtlicher Anforderungen</li>
        </ul>
        Die Verarbeitung Ihrer Daten erfolgt in dem Umfang, der zur Erfüllung der genannten Zwecke notwendig ist. Weiter
        Informationen finden Sie in unserer
        <a [href]="privacyLink" target="_blank" class="underline">Datenschutzerklärung</a>.
      </p-overlayPanel>

      @if (privacyHint) {
        <p-message
          severity="warn"
          class="mt-2"
          data-automationId="accept-privacy-hint"
          text="Bitte akzeptieren Sie die Datenschutzerklärung um fortzufahren."
        ></p-message>
      }
      <div class="mt-2"></div>
    }

    <div class="flex-1 mt-2">
      <p-button
        (click)="sendData()"
        [disabled]="demoMode"
        [label]="field.btnLabel"
        [loading]="loading"
        data-automationId="send-btn"
        icon="pi {{ CFG_ICON.CHECK }}"
        styleClass="full-width"
      ></p-button>
    </div>
  </ng-container>
</div>
