import { Injectable } from '@angular/core';
import { ConfigurationService } from './configuration.service';
import { Configuration, LocalCodes } from '@kfd/core';

/**
 * @deprecated use ConfigService instead
 */
@Injectable()
export class SettingsService {
  private configuration: Configuration | undefined;

  constructor(private configurationService: ConfigurationService) {
    this.configuration = this.configurationService.cfgUtil.getCfg();
  }

  /**
   * @deprecated use ConfigService.locale instead
   */
  public locale(): string {
    if (this.configuration?.settings?.l10n?.locale) {
      return this.configuration?.settings?.l10n?.locale;
    }
    // todo translate navigator language in local codes (eg de --> DE_DE)
    // const locale = navigator.language ? navigator.language : LocalCodes.DE_DE;
    return LocalCodes.DE_DE;
  }
}
