@if (editMode) {
  @if (form) {
    <form (keyup.enter)="saveValue()" (ngSubmit)="saveValue()" [formGroup]="form">
      <p-input-group
        [ngClass]="{ valid: form.get('value')?.valid, invalid: form.get('value')?.dirty && form.get('value')?.invalid }">
        @if (icon) {
          <p-inputgroup-addon>
            <i class="pi {{ icon }}"></i>
          </p-inputgroup-addon>
        }
        <p-inputgroup-addon>
          <p-button
            icon="pi {{ ICON.RESET }}"
            data-automationId="btn-reset-changes"
            severity="secondary"
            [disabled]="form.get('value')?.value === originValue"
            (click)="resetValue()"
          ></p-button>
        </p-inputgroup-addon>
        <input
          formControlName="value"
          name="inputValue"
          pInputText
          type="text"
          data-automationId="value-field"
          [min]="minLength"
          [max]="maxLength"
        />
        <p-inputgroup-addon>
          <p-button
            icon="pi {{ ICON.ACCEPT }}"
            data-automationId="btn-accept-changes"
            severity="secondary"
            (click)="saveValue()"
          ></p-button>
        </p-inputgroup-addon>
      </p-input-group>
      @if (validatorErrorMsg) {
        <small class="color-error">{{ validatorErrorMsg }}</small>
      } @else if (hint) {
        <small>{{ hint }}</small>
      }
    </form>
  }
} @else {
  <p-inputgroup [ngClass]="{ invalid: invalid }">
    @if (icon) {
      <p-inputgroup-addon>
        <i class="pi {{ icon }}"></i>
      </p-inputgroup-addon>
    }
    <input
      [disabled]="true"
      [ngModel]="value"
      name="inputValue"
      pInputText
      type="text"
      data-automationId="value-field-readonly"
    />
    <p-button
      icon="pi {{ ICON.EDIT }}"
      data-automationId="btn-edit-values"
      styleClass="input-group-button-end"
      [rounded]="true"
      [outlined]="true"
      (click)="changeEditMode(true)"
    ></p-button>
  </p-inputgroup>
  @if (validatorErrorMsg) {
    <small class="color-error">{{ validatorErrorMsg }}</small>
  }
}
