@if (field) {
  @if (selectionDataList$ | async; as selectionDataList) {
    @switch (field.layout?.type) {
      @case (layouts.BUTTON) {
        @if (screenSize$ | async; as screenSize) {
          <kfd-web-inline-selection
            [config]="{
              direction: 'col',
              minSelections: getMin(),
              maxSelections: getMax(),
              optionIdPath: 'name',
              optionLabelPath: 'label',
              optionDescriptionPath: field.layout?.showDescription ? 'description' : undefined,
              optionValuePath: (field.additionalValues && field.additionalValues.length > 0) ? 'values' : undefined,
              optionImagePath: (field.layout && field.layout.showImage) ? 'image.thumbnail' : undefined,
            }"
            [additionalValues]="field.additionalValues"
            [columns]="screenSize === ScreenSize.LG ? 4 : screenSize === ScreenSize.MD ? 2 : 1"
            [selection]="selection"
            [options]="selectionDataList"
            (selectionChange)="onSelectionChange(selectionDataList, $event)"
          ></kfd-web-inline-selection>
        }

      }
      @default {
        @if (singleSelection) {
          <p-select
            (onChange)="onSelectionChange(selectionDataList, $event.value)"
            [filter]="false"
            [ngModel]="selection.length > 0 ? selection[0] : emptyName"
            [options]="selectionDataList"
            [overlayOptions]="overlayOptions"
            data-automationId="cfg-field-select-singledropdown"
            optionLabel="label"
            optionValue="name"
          >
            <ng-template let-option pTemplate="selectedItem">
              <ng-template [ngTemplateOutletContext]="{ option: option }" [ngTemplateOutlet]="optionTpl"></ng-template>
            </ng-template>
            <ng-template let-option pTemplate="item">
              <ng-template [ngTemplateOutletContext]="{ option: option }" [ngTemplateOutlet]="optionTpl"></ng-template>
            </ng-template>
          </p-select>
        } @else {
          <p-multiSelect
            (onChange)="onSelectionChange(selectionDataList, $event.value)"
            [appendTo]="appendTo"
            [ngModel]="selection"
            [options]="selectionDataList"
            [selectionLimit]="getMax()"
            data-automationId="cfg-field-select-multi"
            optionLabel="label"
            optionValue="name"
          >
            <ng-template let-option pTemplate="item">
              <ng-template [ngTemplateOutletContext]="{ option: option }" [ngTemplateOutlet]="optionTpl"></ng-template>
            </ng-template>
          </p-multiSelect>
        }
      }
    }

    <ng-template #optionTpl let-option="option">
      <ng-container *ngIf="option">
        <div
          [attr.data-option-name]="option.name"
          class="option-item"
          [ngClass]="{ 'second-row': field.additionalValues && field.additionalValues.length > 0 }"
        >
          @if (option.image) {
            <img [src]="option.image.thumbnail ? option.image.thumbnail : option.image.url" />
          }
          <div class="content">
            <div class="label">{{ option.label }}</div>
            @if (field.additionalValues && field.additionalValues.length > 0) {
              <div class="additional-values">
                @for (value of field.additionalValues; track value.label) {
                  <div class="value">{{ value.label }}: {{ findOptionValue(option.values, value.value) }}</div>
                }
              </div>
            }
          </div>
        </div>
      </ng-container>
    </ng-template>
  } @else {
    <div class="flex justify-content-center">
      <i class="color-disabled">Keine Auswahloptionen vorhanden</i>
    </div>
  }
}
