import { AbstractControl, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { Is, StringUtil } from '@kfd/core';
import Validator from 'validatorjs';

export function enumValidator(allowedValues: string[]): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const result = allowedValues.includes(control.value)
      ? null
      : {
          invalidEnum: true,
          message: `Value must be one of ${allowedValues.join(', ')}`,
        };
    return result;
  };
}

export const urlValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
  const value = control.value ? StringUtil.toValidUrl(control.value) : '';
  return new Validator({ value: value }, { value: 'url' }).passes()
    ? null
    : {
        invalidUrl: true,
        message: 'Die URL ist ungültig.',
      };
};

export const saveNameValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
  return control.value && control.value === StringUtil.toSaveString(control.value)
    ? null
    : {
        invalidName: true,
        message: 'Der Name darf nur aus Buchstaben, Zahlen und Bindestrichen bestehen.',
      };
};

export const isInputValueValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
  return control.value && Is.inputValue(control.value) && !Is.emptyInputValue(control.value)
    ? null
    : {
        required: true,
        message: 'Es wurde kein Wert ausgewählt',
      };
};

export const isFilledInputValueValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
  return control.value && Is.inputValue(control.value) && control.value.value !== undefined
    ? null
    : {
        required: true,
        message: 'Es wurde kein Wert eingetragen',
      };
};

export const saveNameValidators = [
  Validators.required,
  Validators.minLength(3),
  Validators.maxLength(40),
  saveNameValidator,
];

export const saveCodeValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
  return control.value && control.value === control.value.replace(/[^A-Z0-9]/gi, '')
    ? null
    : {
        invalidName: true,
        message: 'Der Code darf nur aus Groß-Buchstaben und Zahlen bestehen.',
      };
};

export const saveCodeValidators = [
  Validators.required,
  Validators.minLength(3),
  Validators.maxLength(5),
  saveCodeValidator,
];
