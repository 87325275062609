<p-message [severity]="severity">
  <ng-template #icon>
    <div class="flex flex-1 justify-content-between">
      <div class="flex flex-column justify-content-center">
        @if (text) {
          <strong class="font-bold">{{ text }}</strong>
        }
        @if (info) {
          <div>{{ info }}</div>
        }
      </div>
      @if (!hideActionBtn) {
        <div class="flex-none flex align-items-center">
          @if (link) {
            <p-button
              [label]="actionBtnLabel ?? ''"
              [outlined]="true"
              [rounded]="true"
              [routerLink]="link"
              [pTooltip]="actionBtnTooltip"
              [size]="$any(size)"
              tooltipPosition="left"
              class="ml-4"
              data-automationId="action-btn"
              icon="pi {{ actionBtnIcon }}"
            ></p-button>
          } @else {
            <p-button
              (click)="action.emit()"
              [label]="actionBtnLabel ?? ''"
              [outlined]="true"
              [rounded]="true"
              [pTooltip]="actionBtnTooltip"
              [size]="$any(size)"
              tooltipPosition="left"
              class="ml-4"
              data-automationId="action-btn"
              icon="pi {{ actionBtnIcon }}"
            ></p-button>
          }
        </div>
      }
    </div>
  </ng-template>
</p-message>
