import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'kfd-yes-no-btn',
  templateUrl: './yes-no-btn.component.html',
  styleUrls: ['./yes-no-btn.component.scss'],
  standalone: false,
})
export class YesNoBtnComponent {
  @Output() valueChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Input()
  inputId: string | undefined;

  @Input()
  label = '';

  boolValues = [
    {
      name: 'Ja',
      icon: 'pi pi-check-circle',
      value: true,
    },
    {
      name: 'Nein',
      icon: 'pi pi-times-circle',
      value: false,
    },
  ];

  @Input()
  value = false;

  onValueChange() {
    this.valueChange.emit(this.value);
  }
}
