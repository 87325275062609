import { Component } from '@angular/core';
import { ICON, User } from '@kfd/core';
import { mergeMap, Observable, of } from 'rxjs';
import { AuthService } from '../../services/auth.service';
import { Router, RouterModule } from '@angular/router';
import { PopoverModule } from 'primeng/popover';
import { CommonModule } from '@angular/common';
import { ButtonModule } from 'primeng/button';

@Component({
  selector: 'kfd-user-icon',
  templateUrl: './user-icon.component.html',
  styleUrls: ['./user-icon.component.scss'],
  imports: [PopoverModule, CommonModule, RouterModule, ButtonModule],
  standalone: true,
})
export class UserIconComponent {
  protected icon = ICON;
  protected loading = false;
  protected currentUser$: Observable<User | undefined>;
  protected readonly ICON = ICON;

  protected open = false;

  constructor(
    private readonly router: Router,
    private readonly authService: AuthService,
  ) {
    this.currentUser$ = authService
      .onSignInChange()
      .pipe(mergeMap((signedIn) => (signedIn ? this.authService.currentUser() : of(undefined))));
  }

  public hide(): void {
    this.open = false;
  }

  protected toggle(): void {
    this.open = !this.open;
  }

  protected logout(): void {
    this.loading = true;
    this.authService.signOut().subscribe(() => {
      this.loading = false;
      this.router.navigate(['/auth']);
    });
  }
}
