<ng-container *ngIf="printValueList && printValueList.length > 0; else empty">
  <ng-container *ngFor="let printValue of printValueList">
    <div [attr.data-automationId]="'printvalue-for-' + printValue.label | saveString"
         class="row {{ printValue.style }}">
      <span class="label">{{ printValue.label }}: </span>
      <span [innerHtml]="printValue.value" class="value" data-automationId="printvalue"></span>
    </div>
  </ng-container>
</ng-container>

<ng-template #empty>
  <div class="full-width text-center font-italic" data-automationId="summary-no-values">Keine Werte</div>
</ng-template>
