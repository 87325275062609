import { Injectable, Pipe, PipeTransform } from '@angular/core';
import { StringUtil } from '@kfd/core';

@Pipe({
  name: 'filter',
  standalone: false,
})
@Injectable()
export class FilterPipe implements PipeTransform {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  transform(items: any[], fieldList: string, value: string): any[] {
    if (!items) {
      return [];
    }
    if (!value || value.length == 0) {
      return items;
    }
    const fields = fieldList.split(',').map((field) => field.trim());
    return items.filter((item) => {
      for (const field of fields) {
        if (StringUtil.toSaveString(item[field]).indexOf(StringUtil.toSaveString(value)) != -1) {
          return true;
        }
      }
      return false;
    });
  }
}
