import {
  CLS,
  Create,
  DATA_VALUE_TYPE,
  Field,
  FORM_VALUE_TYPES,
  FormValue,
  FormValueMap,
  FormValueRecord,
  Is,
  MultiFormValue,
  MultiSelectionFormValue,
  SingleFormValue,
  SingleSelectionFormValue,
  TextFieldConfig,
} from '../dtos';
import { DateUtil, NumberUtil, StringUtil } from '../common';
import { getSelectionDataValue } from './base-data.util';

export class FormValueUtil {
  public static new(): FormValueMap {
    return new Map<string, FormValue>();
  }

  public static toRecord(obj: FormValueRecord | FormValueMap): FormValueRecord {
    if (obj instanceof Map) {
      return Object.fromEntries(obj);
    }

    return obj;
  }

  public static createFormValueMap(obj: FormValueRecord | FormValueMap): FormValueMap {
    if (obj instanceof Map) {
      return obj;
    }
    const map = this.new();
    for (const [key, value] of Object.entries(obj)) {
      if (value.cls !== CLS.FORM_VALUE) {
        continue;
      }
      map.set(key, value);
    }
    return map;
  }

  /**
   * creates a diff of two form value records the diff contains all values which are different or new in the second record
   * @param a
   * @param b
   */
  public static diff(a: FormValueRecord | FormValueMap, b: FormValueRecord | FormValueMap): FormValueMap {
    const mapA = this.createFormValueMap(a);
    const mapB = this.createFormValueMap(b);
    const diff = new Map<string, FormValue>();
    for (const [key, value] of mapB) {
      if (!mapA.has(key) || !FormValueUtil.isValueEqual(mapA.get(key) as FormValue, value)) {
        diff.set(key, value);
      }
    }
    return diff;
  }

  public static joinValueMaps(a: FormValueRecord | FormValueMap, b: FormValueRecord | FormValueMap): FormValueMap {
    const mapA = this.createFormValueMap(a);
    const mapB = this.createFormValueMap(b);
    return new Map([...mapA, ...mapB]);
  }

  public static isValueEqual(valueA: FormValue, valueB: FormValue): boolean {
    return FormValueUtil.toString(valueA) === FormValueUtil.toString(valueB);
  }

  public static createFormValueForField(field: Field, value: string | number | boolean): FormValue {
    if (!field.config.dataType) {
      throw new Error('Cannot convert form value for field without data type');
    }
    if (Array.isArray(value)) {
      throw new Error('Only single form values are supported');
    }
    switch (field.config.dataType) {
      case DATA_VALUE_TYPE.BOOL:
        return Create.boolFormValue(!!value);
      case DATA_VALUE_TYPE.STRING:
        return (field.config as TextFieldConfig).multiline
          ? Create.textFormValue(StringUtil.secureString(value.toString()))
          : Create.textFormValue(StringUtil.singleLineString(value.toString()));
      case DATA_VALUE_TYPE.DATE:
        return Create.dateFormValue(DateUtil.toDate(value.toString()));
      case DATA_VALUE_TYPE.NUMERIC:
        return Create.numericFormValue(NumberUtil.toNumber(value));
      default:
        throw new Error(`Unsupported data type "${field.config.dataType}"`);
    }
  }

  /**
   * converts form values to a number
   * @param formValue
   */
  public static toNumber(formValue: FormValue): number | undefined {
    if (formValue === undefined) {
      return undefined;
    }
    if (Is.singleFormValue(formValue)) {
      if (typeof formValue.input.value === 'number') {
        return formValue.input.value;
      }
      const value = formValue.input.value?.toString() ?? '';
      if (!isNaN(parseInt(value))) {
        return parseInt(value);
      }
      return undefined;
    }
    return undefined;
  }

  /**
   * valides form values
   * todo: check more specific eg data types of single form values
   * @param formValue
   */
  public static isValid(formValue: FormValue | undefined): boolean {
    if (!Is.formValue(formValue)) {
      return false;
    }
    switch (formValue.type) {
      case FORM_VALUE_TYPES.SINGLE:
        return (formValue as SingleFormValue).input.value !== undefined;
      case FORM_VALUE_TYPES.MULTI:
        return Array.isArray((formValue as MultiFormValue).valueMap);
      case FORM_VALUE_TYPES.SINGLE_SELECTION:
        return (formValue as SingleSelectionFormValue).selection !== undefined;
      case FORM_VALUE_TYPES.MULTI_SELECTION:
        return Array.isArray((formValue as MultiSelectionFormValue).selection);
      case FORM_VALUE_TYPES.EMPTY:
        return true;
      default:
        throw new Error(`Unsupported value type "${formValue.type}"`);
    }
  }

  /**
   * converts the form value to a basic string
   * for smarter conversion use ConfigurationValueHandler.fieldValue method
   */
  public static toString(formValue: FormValue | undefined, property?: string): string {
    if (formValue === undefined) {
      return '';
    }
    if (Is.singleFormValue(formValue)) {
      return '' + formValue.input.value;
    }
    if (Is.multiFormValue(formValue)) {
      return Object.values(formValue.valueMap).join(',');
    }
    if (Is.singleSelectionFormValue(formValue)) {
      const value = getSelectionDataValue(formValue.selection, property ?? 'label')?.value;
      return value?.toString() ?? '';
    }
    if (Is.multiSelectionFormValue(formValue)) {
      return formValue.selection
        .map((selectionValue) => getSelectionDataValue(selectionValue, property ?? 'label')?.value)
        .join(',');
    }
    if (Is.emptyFormValue(formValue)) {
      return '';
    }
    throw new Error(`Unsupported value type "${formValue.type}"`);
  }
}
