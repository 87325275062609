import { Inject, LOCALE_ID, Pipe, PipeTransform } from '@angular/core';
import { DATA_VALUE_TYPE, FormValue, InputValue, Is } from '@kfd/core';
import { formatDate, formatNumber } from '@angular/common';
import { CfgException } from '../../common/cfg-exception';

@Pipe({
  name: 'formValue',
  standalone: false,
})
export class FormValuePipe implements PipeTransform {
  constructor(@Inject(LOCALE_ID) public locale: string) {}

  transform(formValue: FormValue | undefined): string | number | boolean | undefined {
    if (!formValue || Is.emptyFormValue(formValue)) {
      return '---';
    }
    if (Is.singleFormValue(formValue)) {
      return this.convertValue(formValue.input);
    }
    if (Is.singleSelectionFormValue(formValue)) {
      return formValue.selection.label ? formValue.selection.label : formValue.selection.name;
    }
    if (Is.multiSelectionFormValue(formValue)) {
      return formValue.selection
        .map((selectionData) => (selectionData.label ? selectionData.label : selectionData.name))
        .join(',');
    }

    throw new CfgException(`Form value type "${formValue.type}" is not yet supported`);
  }

  convertValue(inputValue: InputValue): string | number | boolean | undefined {
    switch (inputValue.type) {
      case DATA_VALUE_TYPE.NUMERIC:
        return formatNumber(inputValue.value as number, this.locale);
      case DATA_VALUE_TYPE.DATE:
        return formatDate(inputValue.value as string, 'longDate', this.locale);
      default:
        return inputValue?.value;
    }
  }
}
