import Ajv, { ErrorObject, JSONSchemaType } from 'ajv';
import { Enum, JsonSchema } from './schema-util';
import { ArrayUtil } from '../common';
import { CLS } from '../dtos';

// Type can be: number, integer, string, boolean, array, object or null.
const MONGO_TYPES_TO_AJV = {
  bool: 'boolean',
  date: 'string',
  objectId: 'string',
  double: 'number',
  int: 'integer',
};

export function isSchemaValid(schema: JsonSchema, data: unknown): ErrorObject[] {
  const converted = convertMongoSchemaToAjv(schema);
  // console.log(converted);
  const ajv = new Ajv({
    verbose: false,
    strict: false,
  });
  const validate = ajv.compile(converted);

  if (validate(data)) {
    return [];
  } else {
    // console.log(validate.errors);
    return validate.errors ?? [];
  }
  return [];
}

export interface SchemaErrorDetails {
  validationErrors: ErrorObject[];
  schema: JsonSchema;
}

export function getRelevantAnyOfSchema(
  cls: CLS,
  validationErrors: ErrorObject[],
  schema: JsonSchema,
): SchemaErrorDetails {
  if (!schema.anyOf) {
    return {
      validationErrors,
      schema,
    };
  }

  let relevantIndex: number | undefined;
  let i = 0;
  for (const anyOf of schema.anyOf) {
    // eslint-disable-next-line no-prototype-builtins
    if (anyOf.properties?.hasOwnProperty('cls')) {
      const clsProp: Enum = anyOf.properties['cls'] as unknown as Enum;
      if (clsProp.enum.includes(cls)) {
        relevantIndex = i;
        break;
      }
    }
    i = i + 1;
  }

  if (relevantIndex === undefined) {
    return {
      validationErrors,
      schema,
    };
  }
  return {
    validationErrors: validationErrors.filter((error) => error.schemaPath.startsWith(`#/anyOf/${relevantIndex}`)),
    schema: schema.anyOf[relevantIndex],
  };
}

export function convertMongoSchemaToAjv(schema: JsonSchema, isProperties = false): JSONSchemaType<unknown> {
  if (typeof schema !== 'object') {
    return schema;
  }
  const avjSchema = {};
  // const isObject = (Object.keys(schema).includes('bsonType') && schema['bsonType'] === 'object')
  //   ||(Object.keys(schema).includes('type') && schema['type'] === 'object');

  //iterate object keys
  Object.keys(schema).forEach((key) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    let value = schema[key];
    if (typeof value === 'object' && value !== null) {
      if (Array.isArray(value)) {
        value = value.map((item) => convertMongoSchemaToAjv(item));
      } else {
        value = convertMongoSchemaToAjv(value, key === 'properties');
      }
    }
    if (key === 'bsonType') {
      key = 'type';
    }
    if (!isProperties && key === 'type') {
      if (Array.isArray(value)) {
        value = ArrayUtil.distinct(
          value.map((item) => {
            if (Object.keys(MONGO_TYPES_TO_AJV).includes(item)) {
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              return MONGO_TYPES_TO_AJV[item];
            }
            return item;
          }),
        );
      } else {
        if (Object.keys(MONGO_TYPES_TO_AJV).includes(value)) {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          value = MONGO_TYPES_TO_AJV[value];
        }
      }
    }
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    avjSchema[key] = value;
  });
  return avjSchema as JSONSchemaType<unknown>;
}
