import { Component, Input, ViewEncapsulation } from '@angular/core';
import { WebPage } from '@kfd/core';
import { RouterLink } from '@angular/router';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'kfd-page-content',
  templateUrl: './page-content.component.html',
  styleUrls: ['./page-content.component.scss'],
  standalone: true,
  encapsulation: ViewEncapsulation.None,
  imports: [RouterLink, CommonModule],
})
export class PageContentComponent {
  @Input()
  page: WebPage | undefined;

  @Input()
  showTitle = true;

  @Input()
  sectionId: string | undefined;

  @Input()
  showToc = true;

  @Input()
  showAnchorLinks = true;
}
