import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
  Create,
  DATA_VALUE_TYPE,
  ICON,
  InputValue,
  InputValueProperties,
  Is,
  ObjectUtil,
  SAVE_DATE_FORMAT_PRIME,
  ValueTypes,
} from '@kfd/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'kfd-data-value',
  templateUrl: './data-value.component.html',
  styleUrls: ['./data-value.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: DataValueComponent,
    },
  ],
  standalone: false,
})
export class DataValueComponent implements ControlValueAccessor {
  @Output()
  public valueChange: EventEmitter<InputValue> = new EventEmitter<InputValue>();
  @Input()
  public editMode = true;
  @Input()
  public settings = false;
  @Input()
  public emitEmpty = false;
  @Input()
  public createDefaultInputValue = false;

  protected readonly DATA_VALUE_TYPE = DATA_VALUE_TYPE;
  protected readonly SAVE_DATE_FORMAT = SAVE_DATE_FORMAT_PRIME;
  protected valueProperties: InputValueProperties = {};
  protected disabled = false;
  protected readonly ICON = ICON;
  private onChanged: CallableFunction | undefined;
  private onTouched: CallableFunction | undefined;

  private _value: InputValue = Create.emptyInputValue();

  get value(): InputValue | undefined {
    return this._value;
  }

  @Input()
  set value(value: InputValue | undefined) {
    if (value === undefined || !Is.value(value)) {
      this.createInputValueWithDefault();
      return;
    }

    this._value = ObjectUtil.clone(value);
    if (this._value?.properties) {
      this.valueProperties = this._value.properties;
    }
  }

  private _dataValueType: DATA_VALUE_TYPE = DATA_VALUE_TYPE.EMPTY;

  get dataValueType(): DATA_VALUE_TYPE {
    return this._dataValueType;
  }

  @Input()
  set dataValueType(value: DATA_VALUE_TYPE) {
    if (value === undefined) {
      this._dataValueType = DATA_VALUE_TYPE.EMPTY;
    } else {
      this._dataValueType = value;

      if (this.value === undefined) {
        this.createInputValueWithDefault();
      }
    }
  }

  @Input()
  set properties(properties: InputValueProperties) {
    this.valueProperties = properties;
  }

  public writeValue(value: InputValue | undefined): void {
    this.value = value;
  }

  public registerOnChange(onChange: CallableFunction): void {
    this.onChanged = onChange;
  }

  public registerOnTouched(onChange: CallableFunction): void {
    this.onTouched = onChange;
  }

  public setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  // clearValue() {
  //   this.setEmptyValue();
  //   this.valueChange.emit(this.value);
  // }

  protected createInputValueWithDefault(force = false) {
    if (this.dataValueType) {
      if (!this.createDefaultInputValue && !force) {
        this._value = Create.emptyInputValue();
      } else {
        this._value = Create.createDefaultInputValue(this.dataValueType);
        this.valueChange.emit(this.value);
        if (this.onTouched) {
          this.onTouched(this.value);
        }
        if (this.onChanged) {
          this.onChanged(this.value);
        }
      }
    }
  }

  protected setEmptyValue(): void {
    this._value = Create.emptyInputValue();
  }

  protected onValueChange(type: DATA_VALUE_TYPE, value: ValueTypes | null) {
    if (value === null) {
      return;
    }
    this.value = Create.inputValue(type, value);
    if (Is.emptyInputValue(this.value) && !this.emitEmpty) {
      return;
    }
    this.valueChange.emit(this.value);
    if (this.onTouched) {
      this.onTouched(this.value);
    }
    if (this.onChanged) {
      this.onChanged(this.value);
    }
  }
}
