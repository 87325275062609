import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { unvTimeout } from '@kfd/core';

@Component({
  selector: 'kfd-textarea',
  templateUrl: './textarea.component.html',
  styleUrls: ['./textarea.component.scss'],
  standalone: false,
})
export class TextareaComponent {
  @Output()
  public valueChange = new EventEmitter<string>();
  @Input()
  public maxLength = 9000;
  @Input()
  public minLength = 0;
  @Input()
  minLines = 3;
  @Input()
  maxLines = 10;
  @Input()
  disabled = false;
  lineHeight = 0;
  maxHeightReached = false;

  constructor() {
    const fontSize =
      typeof document !== 'undefined' ? parseInt(getComputedStyle(document.documentElement).fontSize) : 16;
    this.lineHeight = fontSize * 1.5;
  }

  private _value = '';

  get value(): string {
    return this._value;
  }

  @Input()
  set value(value: string) {
    this._value = value;
    unvTimeout(() => {
      this.updateSize();
    }, 10);
  }

  private _textarea: ElementRef | undefined;

  @ViewChild('textAreaElement')
  set textarea(value: ElementRef | undefined) {
    this._textarea = value;
    this.updateSize();
  }

  onModelChange(value: string) {
    this.valueChange.emit(value);
    this.updateSize();
  }

  updateSize() {
    if (this._textarea === undefined) {
      return;
    }
    this._textarea.nativeElement.style.height = 0;
    const scrollHeight = this._textarea.nativeElement.scrollHeight;
    const calcLines = Math.floor(scrollHeight / this.lineHeight);
    const lines = calcLines < this.minLines ? this.minLines : calcLines > this.maxLines ? this.maxLines : calcLines + 1;
    this._textarea.nativeElement.style.height = `${lines * this.lineHeight}px`;
    this._textarea.nativeElement.style.overflow = calcLines > lines ? 'auto' : 'hidden';
  }
}
