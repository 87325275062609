export type UnvTimeoutValue = number | NodeJS.Timeout;

export function unvTimeout(cb: CallableFunction, time = 0): UnvTimeoutValue {
  if (typeof window === 'undefined') {
    return setTimeout(cb, time);
  } else {
    return window.setTimeout(cb, time);
  }
}

export function unvInterval(cb: CallableFunction, time = 0): UnvTimeoutValue {
  if (typeof window === 'undefined') {
    return setInterval(cb, time);
  } else {
    return window.setInterval(cb, time);
  }
}

export function clearUnvTimeout(timeout: UnvTimeoutValue | undefined): void {
  if (timeout === undefined) {
    return;
  }
  if (typeof window === 'undefined') {
    clearTimeout(timeout as NodeJS.Timeout);
  } else {
    window.clearTimeout(timeout as number);
  }
}
