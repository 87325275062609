import { Injectable, InjectionToken } from '@angular/core';
import { App, Service } from '@kfd/core';

export const WEB_CONFIG_SERVICE = new InjectionToken<ConfigService>('WEB_CONFIG_SERVICE');

@Injectable({
  providedIn: 'root',
})
export class ConfigService {
  /**
   * session timeout in minutes
   * @private
   */
  private _sessionTimeout = 60;

  get sessionTimeout(): number {
    return this._sessionTimeout;
  }

  set sessionTimeout(value: number) {
    this._sessionTimeout = value;
  }

  private _apps: Partial<Record<App, string>> = {};

  get apps(): Partial<Record<App, string>> {
    return this._apps;
  }

  set apps(value: Partial<Record<App, string>>) {
    this._apps = value;
  }

  private _services: Partial<Record<Service, string>> = {};

  public get services(): Partial<Record<Service, string>> {
    if (!this._services) {
      throw new Error('Services not set');
    }
    return this._services;
  }

  public set services(value: Partial<Record<Service, string>>) {
    this._services = value;
  }

  public getService(service: Service): string {
    if (!this._services[service]) {
      throw new Error(`Service ${service} not found in config`);
    }
    return this._services[service] as string;
  }

  public getApp(app: App): string {
    if (!this._apps[app]) {
      throw new Error(`App ${app} not found in config`);
    }
    return this._apps[app] as string;
  }
}
