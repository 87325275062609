var Messages = require('./messages');
require('./lang/en');
var require_method = require;
var container = {
  messages: {},
  /**
   * Set messages for language
   *
   * @param {string} lang
   * @param {object} rawMessages
   * @return {void}
   */
  _set: function (lang, rawMessages) {
    this.messages[lang] = rawMessages;
  },
  /**
   * Set message for given language's rule.
   *
   * @param {string} lang
   * @param {string} attribute
   * @param {string|object} message
   * @return {void}
   */
  _setRuleMessage: function (lang, attribute, message) {
    this._load(lang);
    if (message === undefined) {
      message = this.messages[lang].def;
    }
    this.messages[lang][attribute] = message;
  },
  /**
   * Load messages (if not already loaded)
   *
   * @param  {string} lang
   * @return {void}
   */
  _load: function (lang) {
    if (!this.messages[lang]) {
      try {
        var rawMessages = require_method('./lang/' + lang);
        this._set(lang, rawMessages);
      } catch (e) {}
    }
  },
  /**
   * Get raw messages for language
   *
   * @param  {string} lang
   * @return {object}
   */
  _get: function (lang) {
    this._load(lang);
    return this.messages[lang];
  },
  /**
   * Make messages for given language
   *
   * @param  {string} lang
   * @return {Messages}
   */
  _make: function (lang) {
    this._load(lang);
    return new Messages(lang, this.messages[lang]);
  }
};
module.exports = container;