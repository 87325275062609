@if (requiredUserAction$ | async; as requiredUserAction) {
  @for (action of requiredUserAction.actions; track action) {
    @switch (action) {
      @case (RequiredUserAction.EMAIL_ACTIVATION) {
        <kfd-action-message
          class="mt-4 mx-6"
          text="Ihre E-Mail Adresse wurde noch nicht bestätigt"
          info="Prüfen Sie Ihre E-Mails und folgen Sie den Anweisungen"
          [actionBtnIcon]="ICON.NEXT"
          [link]="profileLink"
        >
        </kfd-action-message>
      }
      @case (RequiredUserAction.PRIVACY_ACKNOWLEDGE) {
        <kfd-action-message
          class="mt-4 mx-6"
          severity="warn"
          text="Datenschutz - Zustimmung erforderlich"
          info="Bittes lesen Sie die aktuelle Datenschutzbestimmungen und bestätigen Sie diese."
          [actionBtnIcon]="ICON.PRIVACY"
          [actionBtnLabel]="'Jetzt lesen'"
          (action)="acceptPrivacy()"
        >
        </kfd-action-message>
      }
    }
  }
}
