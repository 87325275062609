@if (pageId) {
  <p-button
    (click)="showDialog = !showDialog"
    [outlined]="buttonType === 'round'"
    [rounded]="buttonType === 'round'"
    [text]="buttonType === 'text'"
    [label]="buttonType === 'text' ? 'Mehr erfahren' : ''"
    icon="pi {{ ICON.DOCUMENTATION }}"
    pTooltip="Hilfe / Dokumentation"
    tooltipPosition="left"
  ></p-button>

  <p-dialog [(visible)]="showDialog"
            [closeOnEscape]="true"
            [dismissableMask]="true" [modal]="true"
            [style]="{ width: '80vw' }"
            appendTo="body">
    @if (webPage$ | async; as webPage) {
      <ng-template #header>
        <div class="flex-1 flex justify-content-between align-items-center">
          <div class="flex flex-column justify-content-center">
            <div>Dokumentation</div>
            <h1>{{ webPage.title }}</h1>
          </div>
          <div>
            @if (webPage.id) {
              <a [href]="webApp + '/support/documentation/' + webPage.id" target="_blank" class="mt-4">
                <p-button
                  class="footer-button-right mr-2"
                  label="Dokumentation öffnen"
                  icon="pi {{ ICON.LINK_EXTERNAL }}"
                  pTooltip="Dokumentation in neuer Seite öffnen"
                  tooltipPosition="left"
                  [rounded]="true"
                  [text]="true"
                ></p-button>
              </a>
            }
          </div>
        </div>
      </ng-template>
      <kfd-page-content
        [page]="webPage"
        [sectionId]="sectionId"
        [showToc]="false"
        [showTitle]="false"
        [showAnchorLinks]="false"
      ></kfd-page-content>

    } @else {
      <kfd-loader></kfd-loader>
    }
  </p-dialog>

}
