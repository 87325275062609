import { Component, Input } from '@angular/core';
import {
  Create,
  DATA_VALUE_TYPE,
  EmptyFormValue,
  Is,
  LocalConfig,
  NumericFieldConfig,
  NumericInputValue,
  SingleFormValue,
} from '@kfd/core';
import { CfgSettingsService } from '../../service/cfg-settings.service';
import { SingleInputValueFieldComponent } from '../single-input-value-field.component';
import { Observable, of } from 'rxjs';
import { Nullable } from 'primeng/ts-helpers';
import { CFG_ICON } from '../../../globals';

@Component({
  selector: 'kfd-configurator-field-numeric',
  templateUrl: './configurator-field-numeric.component.html',
  styleUrls: ['./configurator-field-numeric.component.scss'],
  standalone: false,
})
export class ConfiguratorFieldNumericComponent extends SingleInputValueFieldComponent<
  NumericFieldConfig,
  NumericInputValue
> {
  public localConfig: LocalConfig;
  protected readonly CFG_ICON = CFG_ICON;
  protected inputFieldValue: number | undefined;

  constructor(public configService: CfgSettingsService) {
    super();

    this.localConfig = configService.localConfig();
  }

  @Input()
  override set field(field: NumericFieldConfig | undefined) {
    if (!field) {
      return;
    }
    this.fieldConfig = field;

    if (!this.fieldConfig.min) {
      this.fieldConfig.min = Create.inputValue(DATA_VALUE_TYPE.NUMERIC, 0);
    }

    if (!this.fieldConfig?.digits) {
      this.fieldConfig.digits = 0;
    }

    if (!this.fieldConfig.step) {
      this.fieldConfig.step = 1;
    }
  }

  public onInputValueChange(value?: Nullable<number>) {
    if (value === null || value === undefined) {
      this.setFormValue(Create.emptyFormValue());
      this.onValueChange();
      return;
    }
    this.setFormValue(Create.numericFormValue(value));
    this.onValueChange();
  }

  protected override setFormValue(value: SingleFormValue<NumericInputValue> | EmptyFormValue) {
    super.setFormValue(value);
    const formValue = this.getFormValue();
    if (Is.numericFormValue(formValue)) {
      this.inputFieldValue = formValue.input.value;
    } else {
      this.inputFieldValue = undefined;
    }
  }

  protected newFormValue(value?: number): Observable<SingleFormValue<NumericInputValue> | EmptyFormValue> {
    if (value) {
      return of(Create.numericFormValue(value));
    }
    if (this.fieldConfig?.default) {
      return of(Create.numericFormValue(this.fieldConfig.default));
    }
    return of(Create.emptyFormValue());
  }
}
