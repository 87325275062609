import { COMMON_APP_ROUTE_PARTS } from './common-web-app-routes';

export enum CMS_APP_ROUTE_PARAMS {
  PROJECT_ID = 'projectId',
  CONFIGURATION_ID = 'configurationId',
  ENTRY_ID = 'entryId',
}

export enum CMS_APP_ROUTE_PARTS_ROOT {
  AUTHENTICATION = 'auth',
  DASHBOARD = 'dashboard',
  PROJECT = 'project',
  PROJECTS_OVERVIEW = 'projects',
  NOTIFICATIONS = 'notifications',
  SERVICE = 'service',
}

export enum CMS_APP_ROUTE_PARTS_PROJECT {
  // DASHBOARD = 'dashboard',
  SETTINGS = 'settings',
  BASE_DATA = 'base-data',
  BASE_DATA_DATA = 'data',
  BASE_DATA_TEMPLATES = 'templates',
  ASSETS = 'assets',
  STATISTIC = 'statistics',
  CUSTOMER_REQUESTS = 'customer-requests',
  CONFIGURATIONS = 'forms',
}

export enum CMS_APP_ROUTE_PARTS_PROJECT_SETTINGS {
  PERSONAL = 'user-settings',
  BUSINESS_CARD = 'business-card',
  MEMBERSHIP = 'membership',
  USERS = 'users',
  PROJECT = 'project-settings',
}

export interface PageDetails {
  path: string;
  title: string;
}

function page(title: string, path: string) {
  return {
    path,
    title,
  };
}

// interface RouteConfig {
//   [key: string]: string | RouteConfig | PageDetails;
// }

const PROJECT_ROOT = `/${CMS_APP_ROUTE_PARTS_ROOT.PROJECT}/:${CMS_APP_ROUTE_PARAMS.PROJECT_ID}`;
const SETTINGS_ROOT = `${PROJECT_ROOT}/${CMS_APP_ROUTE_PARTS_PROJECT.SETTINGS}`;
const CUSTOMER_REQUESTS_ROOT = `${PROJECT_ROOT}/${CMS_APP_ROUTE_PARTS_PROJECT.CUSTOMER_REQUESTS}`;

export const CMS_APP_ROUTES = {
  root: `/`,
  main: {
    dashboard: page('Projekte', CMS_APP_ROUTE_PARTS_ROOT.DASHBOARD),
    projectOverview: page('Projekte', CMS_APP_ROUTE_PARTS_ROOT.PROJECTS_OVERVIEW),
    notifications: page('Benachrichtigungen', CMS_APP_ROUTE_PARTS_ROOT.NOTIFICATIONS),
    service: page('Hilfe & Support', CMS_APP_ROUTE_PARTS_ROOT.SERVICE),
    userProfile: page('Mein Konto', `${COMMON_APP_ROUTE_PARTS.AUTH}/${COMMON_APP_ROUTE_PARTS.PROFILE}`),
  },
  project: {
    root: page('Projekte', PROJECT_ROOT),
    // dashboard: `${PROJECT_ROOT}/${CMS_APP_ROUTE_PARTS_PROJECT.DASHBOARD}`,
    configurations: {
      root: `${PROJECT_ROOT}/${CMS_APP_ROUTE_PARTS_PROJECT.CONFIGURATIONS}`,
      show: `${PROJECT_ROOT}/${CMS_APP_ROUTE_PARTS_PROJECT.CONFIGURATIONS}/edit/:${CMS_APP_ROUTE_PARAMS.CONFIGURATION_ID}`,
      edit: `${PROJECT_ROOT}/${CMS_APP_ROUTE_PARTS_PROJECT.CONFIGURATIONS}/edit/:${CMS_APP_ROUTE_PARAMS.CONFIGURATION_ID}?edit=true`,
      editEntry: `${PROJECT_ROOT}/${CMS_APP_ROUTE_PARTS_PROJECT.CONFIGURATIONS}/edit/:${CMS_APP_ROUTE_PARAMS.CONFIGURATION_ID}/:${CMS_APP_ROUTE_PARAMS.ENTRY_ID}?edit=true`,
    },
    // settings: `${PROJECT_ROOT}/${CMS_APP_ROUTE_PARTS_PROJECT.SETTINGS}`,
    settings: {
      root: SETTINGS_ROOT,
      businessCard: `${SETTINGS_ROOT}/${CMS_APP_ROUTE_PARTS_PROJECT_SETTINGS.BUSINESS_CARD}`,
      users: `${SETTINGS_ROOT}/${CMS_APP_ROUTE_PARTS_PROJECT_SETTINGS.USERS}`,
      project: `${SETTINGS_ROOT}/${CMS_APP_ROUTE_PARTS_PROJECT_SETTINGS.PROJECT}`,
      membership: `${SETTINGS_ROOT}/${CMS_APP_ROUTE_PARTS_PROJECT_SETTINGS.MEMBERSHIP}`,
    },
    statistic: {
      root: `${PROJECT_ROOT}/${CMS_APP_ROUTE_PARTS_PROJECT.STATISTIC}`,
    },
    customerRequests: {
      root: CUSTOMER_REQUESTS_ROOT,
      details: `${CUSTOMER_REQUESTS_ROOT}/:${CMS_APP_ROUTE_PARAMS.ENTRY_ID}`,
    },
    baseData: {
      root: `${PROJECT_ROOT}/${CMS_APP_ROUTE_PARTS_PROJECT.BASE_DATA}`,
      data: {
        root: page(
          'Stammdatensätze',
          `${PROJECT_ROOT}/${CMS_APP_ROUTE_PARTS_PROJECT.BASE_DATA}/${CMS_APP_ROUTE_PARTS_PROJECT.BASE_DATA_DATA}`,
        ),
        show: page(
          'Stammdatensatz bearbeiten',
          `${PROJECT_ROOT}/${CMS_APP_ROUTE_PARTS_PROJECT.BASE_DATA}/${CMS_APP_ROUTE_PARTS_PROJECT.BASE_DATA_DATA}/:${CMS_APP_ROUTE_PARAMS.ENTRY_ID}`,
        ),
      },
      template: {
        root: page(
          'Vorlagen',
          `${PROJECT_ROOT}/${CMS_APP_ROUTE_PARTS_PROJECT.BASE_DATA}/${CMS_APP_ROUTE_PARTS_PROJECT.BASE_DATA_TEMPLATES}`,
        ),
      },
    },
    assets: {
      root: `${PROJECT_ROOT}/${CMS_APP_ROUTE_PARTS_PROJECT.ASSETS}`,
      show: `${PROJECT_ROOT}/${CMS_APP_ROUTE_PARTS_PROJECT.ASSETS}/:${CMS_APP_ROUTE_PARAMS.ENTRY_ID}`,
    },
  },
};
