import { ChangeDetectorRef, Component, Inject, Input } from '@angular/core';
import {
  BASE_DATA_RESOLVER,
  BaseDataResolver,
  CfgUtil,
  clearUnvTimeout,
  Configuration,
  ConfigurationValueHandler,
  FormValueMap,
  FormValueRecord,
  FormValueUtil,
  PrintValue,
  unvTimeout,
  UnvTimeoutValue,
} from '@kfd/core';
import { CfgSettingsService } from '@kfd/cfg-core';

@Component({
  selector: 'kfd-dynamic-value-list',
  templateUrl: './dynamic-value-list.component.html',
  styleUrls: ['./dynamic-value-list.component.scss'],
  standalone: false,
})
export class DynamicValueListComponent {
  protected printValueList: PrintValue[] = [];
  private _formValueMap: FormValueMap | undefined;
  private initTimeout: UnvTimeoutValue = 0;
  private cfgValueHandler: ConfigurationValueHandler | undefined;

  constructor(
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly cfgSettingsService: CfgSettingsService,
    @Inject(BASE_DATA_RESOLVER) readonly baseDataResolver: BaseDataResolver,
  ) {}

  /**
   * use only configuration or cfgUtil and not both
   * @param value
   */
  @Input()
  public set configuration(value: Configuration | undefined) {
    if (!value) {
      return;
    }
    this.cfgValueHandler = new ConfigurationValueHandler(
      this.cfgSettingsService.localConfig(),
      this.baseDataResolver,
      CfgUtil.create(value),
    );
    this.init();
  }

  /**
   * use only configuration or cfgUtil and not both
   * @param value
   */
  @Input()
  public set cfgUtil(cfgUtil: CfgUtil | undefined) {
    if (!cfgUtil) {
      return;
    }
    this.cfgValueHandler = new ConfigurationValueHandler(
      this.cfgSettingsService.localConfig(),
      this.baseDataResolver,
      cfgUtil,
    );
    this.init();
  }

  private _skipFieldsWithoutValue = false;

  public get skipFieldsWithoutValue(): boolean {
    return this._skipFieldsWithoutValue;
  }

  @Input()
  public set skipFieldsWithoutValue(value: boolean) {
    this._skipFieldsWithoutValue = value;
    this.init();
  }

  public get values(): FormValueMap | undefined {
    return this._formValueMap;
  }

  @Input()
  public set values(value: FormValueMap | FormValueRecord | undefined) {
    if (!value) {
      return;
    }
    this._formValueMap = FormValueUtil.createFormValueMap(value);
    this.cfgValueHandler?.setValueMap(this._formValueMap);
    this.init();
  }

  public init() {
    if (this.initTimeout) {
      clearUnvTimeout(this.initTimeout);
    }
    this.initTimeout = unvTimeout(() => {
      this.loadValueList();
    }, 200);
  }

  private loadValueList() {
    if (!this.values || !this.cfgValueHandler) {
      return;
    }
    this.cfgValueHandler.printValueList(this._skipFieldsWithoutValue).then((printValueList) => {
      this.printValueList = printValueList;
      this.changeDetectorRef.detectChanges();
    });
  }
}
