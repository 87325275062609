import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ICON } from '@kfd/core';
import { MenuItem } from 'primeng/api';

@Component({
  selector: 'kfd-dialog-layout',
  templateUrl: './dialog-layout.component.html',
  styleUrls: ['./dialog-layout.component.scss'],
  standalone: false,
})
export class DialogLayoutComponent {
  icon = ICON;

  @Output()
  reject: EventEmitter<void> = new EventEmitter<void>();
  @Output()
  accept: EventEmitter<void> = new EventEmitter<void>();

  @Input()
  dialogTitle = '';

  @Input()
  showRejectBtn = true;

  @Input()
  showRejectIcon = false;

  @Input()
  showAcceptBtn = true;

  @Input()
  acceptDisabled = false;

  @Input()
  acceptBtnLabel = 'OK';

  @Input()
  acceptBtnIcon = ICON.ACCEPT;

  @Input()
  rejectBtnLabel = 'Abbrechen';

  @Input()
  rejectBtnIcon = ICON.DECLINE;

  @Input()
  docuPageId = '';

  @Input()
  docuSectionId = '';

  @Input()
  loading = false;

  @Input()
  message = false;

  @Input()
  messageSeverity = 'error';

  @Input()
  messageText = 'Ups, da ist etwas schief gelaufen...';

  @Input()
  hideFooter = false;

  @Input()
  disableScrolling = false;

  @Input()
  borderlessContent = false;

  @Input()
  headerMenu: MenuItem[] = [];
}
