import { DeleteEndpoint, GetEndpoint, PostEndpoint, PutEndpoint, Service } from './endpoints';
import {
  InsertResult,
  JwtUser,
  LoginRequestBody,
  RefreshTokenResponseData,
  RegisterRequestBody,
  REQ_PARAMS,
  RequiredActions,
  ResendActivationMailRequestBody,
  SetPasswordBody,
  TestUser,
  User,
  UserInfo,
} from '../dtos';

export enum AUTH_CTRL_EP {
  AUTH = 'auth',
}

export const authServiceEP = {
  healthy: new GetEndpoint<boolean>(Service.AUTH_SERVICE, `/`, null as unknown as boolean),
  auth: {
    login: new PostEndpoint<LoginRequestBody, void>(
      Service.AUTH_SERVICE,
      `/${AUTH_CTRL_EP.AUTH}/login`,
      null as unknown as LoginRequestBody,
      null as unknown as void,
    ),
    logout: new PostEndpoint<void, void>(
      Service.AUTH_SERVICE,
      `/${AUTH_CTRL_EP.AUTH}/logout`,
      null as unknown as void,
      null as unknown as void,
    ),
    refresh: new PostEndpoint<void, RefreshTokenResponseData>(
      Service.AUTH_SERVICE,
      `/${AUTH_CTRL_EP.AUTH}/refresh`,
      null as unknown as void,
      null as unknown as RefreshTokenResponseData,
    ),
    userExists: new GetEndpoint<boolean>(
      Service.AUTH_SERVICE,
      `/${AUTH_CTRL_EP.AUTH}/users/exists/:${REQ_PARAMS.EMAIL}`,
      null as unknown as boolean,
    ),
    register: new PostEndpoint<RegisterRequestBody, InsertResult>(
      Service.AUTH_SERVICE,
      `/${AUTH_CTRL_EP.AUTH}/register`,
      null as unknown as RegisterRequestBody,
      null as unknown as InsertResult,
    ),
    resendActivationMail: new PostEndpoint<ResendActivationMailRequestBody, boolean>(
      Service.AUTH_SERVICE,
      `/${AUTH_CTRL_EP.AUTH}/register/resend-activation-mail`,
      null as unknown as ResendActivationMailRequestBody,
      null as unknown as boolean,
    ),
    csrfToken: new GetEndpoint<string>(
      Service.AUTH_SERVICE,
      `/${AUTH_CTRL_EP.AUTH}/csrf-token`,
      null as unknown as string,
    ),
    validateEmailByCode: new PostEndpoint<void, boolean>(
      Service.AUTH_SERVICE,
      `/${AUTH_CTRL_EP.AUTH}/validate-email/:${REQ_PARAMS.EMAIL}/:${REQ_PARAMS.KEY}`,
      null as unknown as void,
      null as unknown as boolean,
    ),
    google: {
      login: new GetEndpoint<void>(Service.AUTH_SERVICE, `/${AUTH_CTRL_EP.AUTH}/google`, null as unknown as void),
      redirect: new GetEndpoint<void>(
        Service.AUTH_SERVICE,
        `/${AUTH_CTRL_EP.AUTH}/google/redirect`,
        null as unknown as void,
      ),
    },
    e2e: {
      createUser: new PostEndpoint<void, TestUser>(
        Service.AUTH_SERVICE,
        `/${AUTH_CTRL_EP.AUTH}/e2e/create-user`,
        null as unknown as void,
        null as unknown as TestUser,
      ),
      confirmationCode: new GetEndpoint<string | undefined>(
        Service.AUTH_SERVICE,
        `/${AUTH_CTRL_EP.AUTH}/e2e/confirmation-code/:${REQ_PARAMS.EMAIL}`,
        null as unknown as string,
      ),
    },
    currentUser: {
      user: new GetEndpoint<JwtUser>(Service.AUTH_SERVICE, `/${AUTH_CTRL_EP.AUTH}/user`, null as unknown as JwtUser),
      requiredActions: new GetEndpoint<RequiredActions>(
        Service.AUTH_SERVICE,
        `/${AUTH_CTRL_EP.AUTH}/user/email/required-actions`,
        null as unknown as RequiredActions,
      ),
      passwordReset: new PostEndpoint<Partial<User>, boolean>(
        Service.AUTH_SERVICE,
        `/${AUTH_CTRL_EP.AUTH}/user/password-reset`,
        null as unknown as Partial<User>,
        null as unknown as boolean,
      ),
      setPassword: new PostEndpoint<SetPasswordBody, boolean>(
        Service.AUTH_SERVICE,
        `/${AUTH_CTRL_EP.AUTH}/user/set-password`,
        null as unknown as SetPasswordBody,
        null as unknown as boolean,
      ),
      // updatePassword: new PostEndpoint<UpdatePasswordBody, boolean>(
      //   Service.AUTH_SERVICE,
      //   `/${AUTH_CTRL_EP.AUTH}/user/update-password`,
      //   null as unknown as UpdatePasswordBody,
      //   null as unknown as boolean,
      // ),
      // sendValidationEmail: new PostEndpoint<void, boolean>(
      //   Service.AUTH_SERVICE,
      //   `/${AUTH_CTRL_EP.AUTH}/user/send-validation-email`,
      //   null as unknown as void,
      //   null as unknown as boolean,
      // ),
      acceptPrivacy: new PostEndpoint<void, boolean>(
        Service.AUTH_SERVICE,
        `/${AUTH_CTRL_EP.AUTH}/user/accept-privacy`,
        null as unknown as void,
        null as unknown as boolean,
      ),
      delete: new DeleteEndpoint<boolean>(
        Service.AUTH_SERVICE,
        `/${AUTH_CTRL_EP.AUTH}/user`,
        null as unknown as boolean,
      ),
    },
    userProfile: {
      details: new GetEndpoint<User>(
        Service.AUTH_SERVICE,
        `/${AUTH_CTRL_EP.AUTH}/user/profile`,
        null as unknown as User,
      ),
      change: new PutEndpoint<Partial<User>, User>(
        Service.AUTH_SERVICE,
        `/${AUTH_CTRL_EP.AUTH}/user/profile`,
        null as unknown as Partial<User>,
        null as unknown as User,
      ),
      changeEmail: new PutEndpoint<Partial<User>, boolean>(
        Service.AUTH_SERVICE,
        `/${AUTH_CTRL_EP.AUTH}/user/profile/email`,
        null as unknown as Partial<User>,
        null as unknown as boolean,
      ),
    },
    users: {
      info: new GetEndpoint<UserInfo>(
        Service.AUTH_SERVICE,
        `/${AUTH_CTRL_EP.AUTH}/users/profile/:${REQ_PARAMS.ENTRY_ID}`,
        null as unknown as UserInfo,
      ),
    },
  },
};
