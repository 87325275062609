export class NumberUtil {
  public static toNumber(value: string | number | boolean): number {
    const parsed = parseInt(value.toString());
    if (isNaN(parsed)) {
      throw new Error(`Cannot convert to number`);
    }
    return parsed;
  }

  public static round(value: number, digits = 2): number {
    if (isNaN(digits)) {
      throw new Error(`Round error: invalid digits value ${digits}`);
    }
    const factor = Math.pow(10, digits);
    const m = Number((Math.abs(value) * factor).toPrecision(15));
    return (Math.round(m) / factor) * Math.sign(value);
  }

  /**
   * Returns the remainder of the division of the first argument by the second argument.
   * (modulo with floating point numbers)
   * @param dividend
   * @param divisor
   */
  public static fmod(dividend: number, divisor: number): number {
    let multiplier = 0;
    let result = 0;
    const precision = NumberUtil.countDecimals(divisor);

    // eslint-disable-next-line no-constant-condition
    while (true) {
      const tmpResult = NumberUtil.round(divisor * multiplier, precision + 1);
      if (tmpResult > dividend) {
        break;
      }
      result = tmpResult;
      ++multiplier;
    }
    return NumberUtil.round(dividend - result, precision + 1);
  }

  /**
   * Returns the number of decimal places in a number.
   * @param value
   */
  public static countDecimals(value: number): number {
    if (Math.floor(value) === value) return 0;
    return value.toString().split('.')[1].length || 0;
  }
}
