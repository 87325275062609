@if (open) {
  <div class="modal-bg" (click)="hide()"></div>
}

<div [ngClass]="{open: open}" class="wrapper full-height flex flex-column justify-content-between p-2">
  @if (currentUser$ | async; as currentUser) {
    <div [ngClass]="{'not-visible': !open}">
      <div class="username mt-3 mx-2">Hallo {{ currentUser.name }}</div>
    </div>
    <div class="flex-1 flex flex-column justify-content-center" [ngClass]="{'not-visible': !open}">
      <ng-content></ng-content>
      <p-button
        (click)="hide()"
        class="block mt-2"
        icon="pi {{ ICON.USER }}"
        label="Profil"
        routerLink="/auth/profile"
        styleClass="full-width"
        severity="secondary"
      ></p-button>
      <p-button
        (click)="logout(); hide()"
        class="block mt-2"
        icon="pi {{ ICON.SIGN_OUT }}"
        label="Abmelden"
        styleClass="full-width"
        severity="secondary"
      ></p-button>
    </div>
  } @else {
    <div [ngClass]="{'not-visible': !open}">
      <div class="username mt-3 mx-2">Sie sind abgemeldet</div>
    </div>
    <div class="flex-1 flex flex-column justify-content-center" [ngClass]="{'not-visible': !open}">
      <p-button
        (click)="hide()"
        class="block mt-2"
        icon="pi {{ ICON.SIGN_IN }}"
        label="Anmelden / Registrieren"
        routerLink="/auth"
        styleClass="full-width"
        severity="secondary"
      ></p-button>
    </div>
  }
  <span (click)="toggle()" class="icon pi {{!open ? ICON.USER : ICON.DECLINE}}" role="button"></span>
</div>

