import { ObjectId } from 'mongodb';

export type Id = string | ObjectId;

// global user roles
export enum USER_ROLES {
  ADMIN = 'kfd-admin',
  E2E = 'e2e',
}

export enum ApiClientErrors {
  // user not found or invalid credentials
  USER_NOT_FOUND = 'user-not-found',
  // user is not activated (email not verified)
  USER_INACTIVE = 'user-inactive',
  // user has been manually disabled
  USER_DISABLED = 'user-disabled',
  // csrf token is not valid
  INVALID_CSRF_TOKEN = 'invalid-csrf-token',
}
