import { Injectable, OnDestroy } from '@angular/core';
import { CfgCondition, ConditionRef, ConditionValidator } from '@kfd/core';
import { ConfigurationStateService } from './configuration-state.service';
import { filter, Observable, of, Subject, takeUntil } from 'rxjs';
import { ConfigurationService } from './configuration.service';
import { map, startWith } from 'rxjs/operators';

@Injectable()
export class ConfigurationConditionService implements OnDestroy {
  private destroy$ = new Subject<boolean>();
  private conditionResultChange = new Subject<string>();
  private conditionResult = new Map<string, boolean>();

  constructor(
    private configurationStateService: ConfigurationStateService,
    private configurationService: ConfigurationService,
  ) {
    this.configurationService.onCfgChange().subscribe(() => this.init());
  }

  public ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  init() {
    const conditions = this.configurationService.cfgUtil.getConditions();
    //evaluate all configuration conditions on every value change
    this.configurationStateService
      .onValueChange()
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: () => {
          for (const condition of conditions) {
            this.evaluateCondition(condition);
          }
        },
      });
  }

  /**
   * validates conditions for given entry name
   */
  public onEntryConditionResultChange(entryName: string): Observable<boolean> {
    const relatedConditionRefs = this.configurationService.cfgUtil.getEntryConditionRefs(entryName);
    // entries without conditions always return true
    if (relatedConditionRefs.length === 0) {
      return of(true);
    }
    return this.onAnyConditionChange(relatedConditionRefs);
  }

  /**
   * evaluates a single condition with latest values
   * updates the conditionResult map and fires change event
   */
  private evaluateCondition(condition: CfgCondition): void {
    const valueMap = this.configurationStateService.getValueMap();
    const isTrue = ConditionValidator.evaluateGroup(condition.condition, valueMap);
    this.conditionResult.set(condition.name, isTrue);
    this.conditionResultChange.next(condition.name);
  }

  /**
   * validates all given condition refs and returns true if all of them are fulfilled
   */
  private onAnyConditionChange(conditionRefs: ConditionRef[]): Observable<boolean> {
    const conditionNames = conditionRefs.map((conditionRef) => conditionRef.name);
    return this.conditionResultChange.pipe(
      startWith(conditionNames[0]),
      filter((conditionChangeName) => conditionNames.includes(conditionChangeName)),
      map(() => {
        for (const conditionName of conditionNames) {
          const result = this.conditionResult.get(conditionName);
          if (result !== true) {
            return false;
          }
        }
        return true;
      }),
    );
  }
}
