@if (inlineSelectOptions && inlineSelectOptions.length > 0) {
  <ul class="selection-list grid" [ngClass]="{
    'only-label': config.optionLabelPath && !(config.optionDescriptionPath || config.optionValuePath || config.optionImagePath),
    'content-height-m': config.optionImagePath || config.optionDescriptionPath || config.optionValuePath,
    'content-height-l': config.optionDescriptionPath && config.optionValuePath,
    'with-image': config.optionImagePath,
  }">
    @for (inlineSelectOption of inlineSelectOptions; track inlineSelectOption.id) {
      <li
        [attr.data-automationId]="'option-' + inlineSelectOption.id"
        class="select-option"
        [ngClass]="{
          'col-12': (columns !== 2 && columns !== 4),
          'col-6': columns === 2,
          'col-3': columns === 4,
          selected: inlineSelectOption.selected,
          selectable: inlineSelectOption.selected || !maxSelectionsReached
        }"
        role="checkbox"
        [attr.aria-checked]="inlineSelectOption.selected"
        tabindex="0"
        [attr.aria-labelledby]="'checkbox-label-' + inlineSelectOption.id"
        (click)="toggleSelection(inlineSelectOption, !inlineSelectOption.selected)"
        (keydown.space)="toggleSelection(inlineSelectOption, !inlineSelectOption.selected)"
      >
        <div class="content">
          @if (config.optionImagePath) {
            <div class="image-wrapper"
                 [attr.style]="'background-image: url(' + inlineSelectOption.image + ')'"
                 [ngClass]="{
            image: !!inlineSelectOption.image,
            'no-image': !inlineSelectOption.image
            }">
            </div>
          }
          <div class="details">
            <div class="label" [attr.id]="'checkbox-label-' + inlineSelectOption.id">
              {{ inlineSelectOption.label }}
            </div>
            <!--            <p-checkbox [binary]="true"-->
            <!--                        [ngModel]="inlineSelectOption.selected"-->
            <!--                        (ngModelChange)="toggleSelection(inlineSelectOption, $event.checked)"-->
            <!--            ></p-checkbox>-->
            <!--            <input type="checkbox" [ngModel]="inlineSelectOption.selected"-->
            <!--                   aria-hidden="true"-->
            <!--                   (ngModelChange)="$event.stopPropagation(); toggleSelection(inlineSelectOption, $event)" />-->
            @if (config.optionDescriptionPath) {
              <div class="description">
                @if (inlineSelectOption.description) {
                  <p>{{ inlineSelectOption.description }}</p>
                }
              </div>
            }
            @if (inlineSelectOption.additionalProperties && inlineSelectOption.additionalProperties.length > 0) {
              <div class="additional-values">
                @for (value of inlineSelectOption.additionalProperties; track value.label) {
                  <div class="value">{{ value.label }}: {{ value.value }}</div>
                }
              </div>
            }
          </div>
        </div>
      </li>
    }
  </ul>
} @else {
  <i class="font-italic">Keine Auswahloptionen vorhanden</i>
}
