import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CACHE_XL, contentServiceEP, DocuStructure, FaqPage, WebPage } from '@kfd/core';
import { WebEndpointService } from './web-endpoint.service';
import { CachedWebEndpointService } from './cached-web-endpoint.service';

@Injectable({
  providedIn: 'root',
})
export class ContentService {
  private cwes: CachedWebEndpointService;

  constructor(readonly webEndpointService: WebEndpointService) {
    this.cwes = new CachedWebEndpointService(webEndpointService, CACHE_XL);
  }

  getPage(identifier: string): Observable<WebPage> {
    return this.cwes.get(contentServiceEP.page.getSingle, [identifier], {
      useCache: true,
    });
  }

  getDocuStructure(): Observable<DocuStructure> {
    return this.cwes.get(contentServiceEP.docu.structure, [], {
      useCache: true,
    });
  }

  getDocuPage(identifier: string): Observable<WebPage> {
    return this.cwes.get(contentServiceEP.docu.page, [identifier], {
      useCache: true,
    });
  }

  getFaqPage(): Observable<FaqPage> {
    return this.cwes.get(contentServiceEP.faq, [], {
      useCache: true,
    });
  }
}
