import {
  CONDITIONAL_OPERATOR,
  ConditionComparisonValue,
  ConditionGroup,
  DATA_VALUE_TYPE,
  InputValue,
  Is,
  LOGICAL_OPERATOR,
  SimpleCondition,
  Value,
} from '../../dtos/index';
import dayjs from 'dayjs';
import { Create, FormValueMap } from '../../dtos';
import { DataUtil } from '../../common';

const OPERATOR_WITH_VALUE = [
  CONDITIONAL_OPERATOR.EQUALS,
  CONDITIONAL_OPERATOR.NOT_EQUALS,
  CONDITIONAL_OPERATOR.LESS,
  CONDITIONAL_OPERATOR.LESS_OR_EQUAL,
  CONDITIONAL_OPERATOR.GREATER,
  CONDITIONAL_OPERATOR.GREATER_OR_EQUAL,
  CONDITIONAL_OPERATOR.CONTAINS,
];

export class ConditionValidator {
  /**
   * Evaluates a list of condition groups.
   * Groups are always and-linked
   */
  public static evaluateGroups(conditionGroups: ConditionGroup[], formValueMap: FormValueMap): boolean {
    if (conditionGroups.length === 0) {
      return true;
    }
    for (const conditionGroup of conditionGroups) {
      if (!ConditionValidator.evaluateGroup(conditionGroup, formValueMap)) {
        return false;
      }
    }
    return true;
  }

  public static evaluateGroup(conditionGroup: ConditionGroup, formValueMap: FormValueMap): boolean {
    let containsInvalid = false;
    let containsValid = false;

    for (const condition of conditionGroup.conditions) {
      const conditionValid = this.evaluateCondition(condition, formValueMap);
      if (conditionValid) {
        containsValid = true;
      } else {
        containsInvalid = true;
      }
    }

    switch (conditionGroup.lop) {
      case LOGICAL_OPERATOR.AND:
        return containsValid === true && containsInvalid === false;
      case LOGICAL_OPERATOR.OR:
        return containsValid === true;
      default:
        throw new Error(`Unexpected logical operator "${conditionGroup.lop}"`);
    }
  }

  /**
   * evaluates a single condition
   */
  public static evaluateCondition(condition: SimpleCondition, formValueMap: FormValueMap): boolean {
    let conditionValid;

    let givenFormValue = formValueMap.get(condition.value1.name);
    if (givenFormValue === undefined || (Array.isArray(givenFormValue) && givenFormValue.length === 0)) {
      givenFormValue = Create.emptyFormValue();
    }

    const comparisonValue = ConditionValidator.getComparisonValue(formValueMap, condition.value2);

    if (Is.singleFormValue(givenFormValue)) {
      conditionValid = this.evaluateSingleValueCondition(givenFormValue.input, condition.op, comparisonValue);
    } else if (Is.singleSelectionFormValue(givenFormValue)) {
      if (!givenFormValue.selection || Is.emptyFormValue(givenFormValue.selection)) {
        conditionValid = condition.op === CONDITIONAL_OPERATOR.EMPTY;
      } else {
        const value = DataUtil.selectionDataValue(givenFormValue.selection, condition.value1.property);
        conditionValid = ConditionValidator.evaluateSingleValueCondition(value, condition.op, comparisonValue);
      }
    } else if (Is.multiSelectionFormValue(givenFormValue)) {
      if (givenFormValue.selection.length === 0) {
        conditionValid = condition.op === CONDITIONAL_OPERATOR.EMPTY;
      } else {
        conditionValid = ConditionValidator.evaluateMultiValueCondition(
          givenFormValue.selection.map((selectionData) =>
            DataUtil.selectionDataValue(selectionData, condition.value1.property),
          ),
          condition.op,
          comparisonValue,
        );
      }
    } else if (Is.emptyFormValue(givenFormValue)) {
      conditionValid = this.evaluateSingleValueCondition(undefined, condition.op, comparisonValue);
    } else if (Is.multiFormValue(givenFormValue)) {
      throw new Error(`Multi type is not yet supported for conditions`);
    } else {
      throw new Error(`Unsupported form value type "${givenFormValue.type}"`);
    }

    return conditionValid === true;
  }

  public static evaluateSingleValueCondition(
    givenValue: Value | undefined,
    operator: CONDITIONAL_OPERATOR,
    expectedValue: Value | undefined,
  ): boolean {
    if (givenValue === undefined) {
      return operator === CONDITIONAL_OPERATOR.EMPTY;
    }
    if (expectedValue === undefined) {
      return operator !== CONDITIONAL_OPERATOR.EMPTY;
    }
    const type = givenValue?.type ? givenValue.type : expectedValue.type;
    if (OPERATOR_WITH_VALUE.indexOf(operator) > -1) {
      this.validateTypeMatch(expectedValue, givenValue);
    }
    switch (type) {
      case DATA_VALUE_TYPE.STRING:
        return this.evaluateStringValues(expectedValue.value as string, operator, givenValue.value as string);
      case DATA_VALUE_TYPE.NUMERIC:
        return this.evaluateNumericValues(expectedValue.value as number, operator, givenValue.value as number);
      case DATA_VALUE_TYPE.BOOL:
        return this.evaluateBooleanValues(expectedValue.value as boolean, operator, givenValue.value as boolean);
      case DATA_VALUE_TYPE.DATE:
        return this.evaluateDateValues(expectedValue.value as string, operator, givenValue.value as string);
      case DATA_VALUE_TYPE.EMPTY:
        return Is.emptyInputValue(givenValue) && operator === CONDITIONAL_OPERATOR.EMPTY;
      default:
        throw new Error(`Data type "${type}" is not supported in conditions (${operator})`);
    }
  }

  public static evaluateMultiValueCondition(
    givenValue: Value[] | undefined,
    operator: CONDITIONAL_OPERATOR,
    expectedValue: Value | undefined,
  ): boolean {
    if (operator === CONDITIONAL_OPERATOR.EMPTY) {
      return !givenValue || givenValue.length === 0;
    }

    if (!givenValue) {
      throw new Error(`Given value is undefined, but not allowed for operator ${operator}`);
    }

    if (operator === CONDITIONAL_OPERATOR.NOT_EMPTY) {
      return givenValue.length > 0;
    }

    if (!expectedValue) {
      throw new Error(`Expected value is undefined, but not allowed for operator ${operator}`);
    }

    switch (operator) {
      case CONDITIONAL_OPERATOR.CONTAINS:
        return givenValue.some((value) => value.value === expectedValue.value);
      case CONDITIONAL_OPERATOR.GREATER:
        if (!Is.numericInputValue(expectedValue)) {
          throw new Error(`Expected value is not numeric for operator ${operator}`);
        }
        return givenValue.length > expectedValue.value;
      case CONDITIONAL_OPERATOR.GREATER_OR_EQUAL:
        if (!Is.numericInputValue(expectedValue)) {
          throw new Error(`Expected value is not numeric for operator ${operator}`);
        }
        return givenValue.length >= expectedValue.value;
      case CONDITIONAL_OPERATOR.LESS:
        if (!Is.numericInputValue(expectedValue)) {
          throw new Error(`Expected value is not numeric for operator ${operator}`);
        }
        return givenValue.length < expectedValue.value;
      case CONDITIONAL_OPERATOR.LESS_OR_EQUAL:
        if (!Is.numericInputValue(expectedValue)) {
          throw new Error(`Expected value is not numeric for operator ${operator}`);
        }
        return givenValue.length <= expectedValue.value;
      case CONDITIONAL_OPERATOR.EQUALS:
        if (!Is.numericInputValue(expectedValue)) {
          throw new Error(`Expected value is not numeric for operator ${operator}`);
        }
        return givenValue.length === expectedValue.value;
      case CONDITIONAL_OPERATOR.NOT_EQUALS:
        if (!Is.numericInputValue(expectedValue)) {
          throw new Error(`Expected value is not numeric for operator ${operator}`);
        }
        return givenValue.length !== expectedValue.value;
      default:
        throw new Error(`Condition operation "${operator}" is not supported for multi values`);
    }
  }

  private static getComparisonValue(
    formValueMap: FormValueMap,
    value: ConditionComparisonValue,
  ): InputValue | undefined {
    if (value === undefined) {
      return undefined;
    }
    if (Is.inputValue(value)) {
      return value;
    }
    if (Is.fieldRef(value)) {
      const formValue = formValueMap.get(value.name);
      if (formValue === undefined) {
        return Create.emptyInputValue();
      }
      if (Is.singleFormValue(formValue)) {
        return formValue.input;
      }
      if (Is.singleSelectionFormValue(formValue)) {
        return DataUtil.selectionDataValue(formValue.selection, value.property);
      }
      if (Is.multiSelectionFormValue(formValue)) {
        throw new Error(`Multi selection is not yet supported for conditions`);
        // return Create.calculation(
        //   DataUtil.selectionDataValues(formValue.selection).map((selectionData) =>
        //     DataUtil.selectionDataValue(selectionData, value.property),
        //   ),
        //   CalcOperation.ADD,
        // );
      }
      if (Is.emptyFormValue(formValue)) {
        return Create.emptyInputValue();
      }
      throw new Error(`Unsupported form value type "${formValue.type}"`);
    }
    throw new Error(`Unsupported value type "${value}"`);
  }

  private static evaluateBooleanValues(expected: boolean, op: CONDITIONAL_OPERATOR, given: boolean): boolean {
    switch (op) {
      case CONDITIONAL_OPERATOR.EQUALS:
        return expected === given;
      case CONDITIONAL_OPERATOR.NOT_EQUALS:
        return expected !== given;
      default:
        throw new Error(`Condition operation "${op}" is not supported for boolean values`);
    }
  }

  private static evaluateNumericValues(expected: number, op: CONDITIONAL_OPERATOR, given: number): boolean {
    switch (op) {
      case CONDITIONAL_OPERATOR.EQUALS:
        return expected === given;
      case CONDITIONAL_OPERATOR.NOT_EQUALS:
        return expected !== given;
      case CONDITIONAL_OPERATOR.GREATER:
        return given > expected;
      case CONDITIONAL_OPERATOR.GREATER_OR_EQUAL:
        return given >= expected;
      case CONDITIONAL_OPERATOR.LESS:
        return given < expected;
      case CONDITIONAL_OPERATOR.LESS_OR_EQUAL:
        return given <= expected;
      case CONDITIONAL_OPERATOR.EMPTY:
        return isNaN(given);
      case CONDITIONAL_OPERATOR.NOT_EMPTY:
        return !isNaN(given);
      default:
        throw new Error(`Condition operation "${op}" is not supported for numeric values`);
    }
  }

  private static evaluateStringValues(expected: string, op: CONDITIONAL_OPERATOR, given: string): boolean {
    switch (op) {
      case CONDITIONAL_OPERATOR.CONTAINS:
        return given.includes(expected);
      case CONDITIONAL_OPERATOR.EQUALS:
        return expected === given;
      case CONDITIONAL_OPERATOR.NOT_EQUALS:
        return expected !== given;
      case CONDITIONAL_OPERATOR.EMPTY:
        return given.length === 0;
      case CONDITIONAL_OPERATOR.NOT_EMPTY:
        return given.length > 0;
      default:
        throw new Error(`Condition operation "${op}" is not supported for text values`);
    }
  }

  private static evaluateDateValues(expected: string, op: CONDITIONAL_OPERATOR, given: string): boolean {
    switch (op) {
      case CONDITIONAL_OPERATOR.EQUALS:
        return dayjs(expected).isSame(dayjs(given));
      case CONDITIONAL_OPERATOR.NOT_EQUALS:
        return !dayjs(expected).isSame(dayjs(given));
      case CONDITIONAL_OPERATOR.GREATER:
        return dayjs(given).isAfter(dayjs(expected));
      case CONDITIONAL_OPERATOR.GREATER_OR_EQUAL:
        return dayjs(given).isAfter(dayjs(expected)) || dayjs(given).isSame(dayjs(expected));
      case CONDITIONAL_OPERATOR.LESS:
        return dayjs(given).isBefore(dayjs(expected));
      case CONDITIONAL_OPERATOR.LESS_OR_EQUAL:
        return dayjs(given).isBefore(dayjs(expected)) || dayjs(given).isSame(dayjs(expected));
      case CONDITIONAL_OPERATOR.EMPTY:
        return given.length === 0;
      case CONDITIONAL_OPERATOR.NOT_EMPTY:
        return dayjs(given).isValid();
      default:
        throw new Error(`Condition operation "${op}" is not supported for date values`);
    }
  }

  /**
   *
   * @param expectedValue (condition value)
   * @param givenValue (input value)
   * @private
   */
  private static validateTypeMatch(expectedValue: Value, givenValue: Value): void {
    if (givenValue.type === DATA_VALUE_TYPE.EMPTY) {
      return;
    }
    if (givenValue.type !== expectedValue.type) {
      throw new Error(
        'Data type missmatch, different types cannot be evaluated ' + givenValue.type + ' | ' + expectedValue.type,
      );
    }

    // if (givenValue.value === undefined || expectedValue.value === undefined) {
    //   throw new Error("Undefined values cannot be evaluated");
    // }
  }
}
