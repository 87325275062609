<kfd-dialog-layout
  (accept)="onAccept()"
  (reject)="onReject()"
  [acceptDisabled]="!privacyAccepted"
  [disableScrolling]="true"
  [loading]="loading"
  [showAcceptBtn]="!rejected"
  [showRejectBtn]="!rejected"
  acceptBtnLabel="Zustimmung speichern"
  dialogTitle="Zustimmung erforderlich"
  rejectBtnLabel="Ablehnen (und abmelden)"
>

  @if (rejected) {
    <p-message
      severity="warn"
      text="Sie wurden abgemeldet."
    >
    </p-message>
    <p>
      Ohne eine Zustimmung zu unserer Datenschutzerklärung, können Sie unseren Service nicht länger in Anspruch nehmen.
    </p>
    <p>Wenn Sie sich erneut anmelden, können Sie der Datenschutzerklärung zustimmen.</p>
    <p>Sollten Sie eine Löschung ihres Benutzerkontos wünschen, wenden Sie sich bitte im Kontaktformular an uns.</p>

    <p-button
      (click)="reload()"
      icon="pi {{ICON.DECLINE}}"
      label="Fenster schließen"
      [rounded]="true"
      [outlined]="true"
    ></p-button>
  } @else {

    <p-message
      severity="info"
      text="Für die weitere Verwendung unseres Services müssen Sie der aktuellen Datenschutzerklärung zustimmen"
    >
    </p-message>

    <kfd-static-content identifier="privacy" class="mt-2"></kfd-static-content>

    <div class="field-checkbox mt-4">
      <p-checkbox
        class="mt-2"
        [binary]="true"
        [(ngModel)]="privacyAccepted"
        inputId="privacyAccepted"
      >
      </p-checkbox>
      <label for="privacyAccepted" class="font-bold">Ich habe die Datenschutzerklärung gelesen und stimme den Bedinungen
        zu.</label>
    </div>
  }
</kfd-dialog-layout>
