import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'toArray',
  standalone: false,
})
export class ToArrayPipe implements PipeTransform {
  transform(value: unknown): unknown {
    if (Array.isArray(value)) {
      return value;
    }
    return [value];
  }
}
