import { ChangeDetectionStrategy, Component, EventEmitter, Inject, Input, Output } from '@angular/core';
import {
  BASE_DATA_RESOLVER,
  BaseDataResolver,
  ConfigurationValueHandler,
  Create,
  DATA_VALUE_TYPE,
  DisplayDataFieldConfig,
  EmptyFormValue,
  InputValue,
  Is,
  ObjectUtil,
  PrintValue,
  SingleFormValue,
} from '@kfd/core';
import { mergeMap, Observable, of, tap } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { CfgSettingsService, ConfigurationService, ConfigurationStateService } from '../../service';

@Component({
  selector: 'kfd-configurator-field-readonly',
  templateUrl: './configurator-field-readonly.component.html',
  styleUrls: ['./configurator-field-readonly.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class ConfiguratorFieldReadonlyComponent {
  @Output()
  public valueChange: EventEmitter<SingleFormValue<InputValue> | EmptyFormValue> = new EventEmitter();
  protected printValue$: Observable<PrintValue | undefined> | undefined;
  private readonly configurationValueHandler: ConfigurationValueHandler;

  constructor(
    private readonly cfgSettingsService: CfgSettingsService,
    private readonly configurationService: ConfigurationService,
    private readonly stateService: ConfigurationStateService,
    @Inject(BASE_DATA_RESOLVER) readonly baseDataResolver: BaseDataResolver,
  ) {
    this.configurationValueHandler = new ConfigurationValueHandler(
      this.cfgSettingsService.localConfig(),
      this.baseDataResolver,
      this.configurationService.cfgUtil,
    );
  }

  private _field: DisplayDataFieldConfig | undefined;

  public get field(): DisplayDataFieldConfig | undefined {
    return this._field;
  }

  @Input()
  public set field(value: DisplayDataFieldConfig | undefined) {
    if (!value) {
      return;
    }
    this._field = value;
    this.initializeField();
  }

  private _name: string | undefined;

  public get name(): string | undefined {
    return this._name;
  }

  @Input()
  set name(value: string | undefined) {
    this._name = value;
    this.initializeField();
  }

  initializeField() {
    // console.log('debug', 'initializeField', this.field, this.name);
    this.printValue$ = this.printValue().pipe(
      tap((printValue) => {
        if (printValue !== undefined && this.name) {
          //emitting value change if the value does not equal the stored one
          const newInputValue = Create.inputValue(DATA_VALUE_TYPE.STRING, printValue.value);
          const stateFormValue = this.stateService.getValue(this.name);

          if (Is.emptyFormValue(stateFormValue)) {
            this.valueChange.emit(Create.singleFormValue(newInputValue));
          } else if (Is.singleFormValue(stateFormValue) && !ObjectUtil.equals(stateFormValue.input, newInputValue)) {
            this.valueChange.emit(Create.singleFormValue(newInputValue));
          }
        }
      }),
    );
  }

  private printValue(): Observable<PrintValue | undefined> {
    if (!this.field || !this.name) {
      return of(undefined);
    }
    if (Is.calculationRef(this.field.value) || Is.calculation(this.field.value) || Is.fieldRef(this.field.value)) {
      return this.getPrintValueForField(this.name).pipe(
        map((printValue) => {
          return {
            label: printValue?.label ?? '',
            key: printValue?.key ?? '',
            //todo make empty value ("---") configurable
            value: printValue?.value && printValue?.value !== '' ? printValue.value : '---',
          };
        }),
      );
    }

    if (Is.inputValue(this.field.value)) {
      return of({
        key: '',
        label: '',
        value: this.field.value.value?.toString() ?? '',
      });
    }

    return of(undefined);
  }

  private getPrintValueForField(fieldName: string, startEmpty = true): Observable<PrintValue | undefined> {
    const mergeMapFn = mergeMap(() => {
      if (!this.configurationService.configuration) {
        return of(undefined);
      }
      this.configurationValueHandler.setValueMap(
        this.stateService.getValueMap(this.configurationService.cfgUtil.getFieldNames()),
      );
      return this.configurationValueHandler.fieldValue(fieldName);
    });

    //todo run only on relevant field changes
    if (startEmpty) {
      return this.stateService.onValueChange().pipe(startWith(undefined), mergeMapFn);
    }
    return this.stateService.onValueChange().pipe(mergeMapFn);
  }
}
