import {
  BaseDataRef,
  CalcOperation,
  Calculation,
  CalculationRef,
  DATA_VALUE_TYPE,
  FieldRef,
  FormValue,
  InputValue,
  Is,
  SelectionData,
  SimpleCondition,
  Value,
  ValueCalculation,
} from '../../dtos/index';
import { ConditionValidator } from '../condition/condition-validator';
import { Create } from '../../dtos/cls-creator';
import { DataUtil, ObjectUtil } from '../../common';
import { getSelectionDataValue } from '../base-data.util';
import { CalculationDetails, CalculationHandler } from './calculation-handler';

export interface FieldRefResolver {
  resolve(fieldRef: FieldRef): Promise<FormValue>;
}

export const BASE_DATA_RESOLVER = 'BaseDataResolver';

export interface BaseDataResolver {
  resolve(baseDataRef: BaseDataRef): Promise<SelectionData | undefined>;
}

export interface CalculationRefResolver {
  resolve(calculationRef: CalculationRef): Promise<Calculation | undefined>;
}

export enum ResolveErrors {
  UNKNOWN = 'UNKNOWN',
  INVALID_REFERENCE = 'invalid-ref',
  INVALID_DATA_TYPE = 'invalid-data-type',
  NON_UNIQUE_REF = 'non-unique-ref',
}

/**
 * Helper class to resolve all references in a calculation such as field references and base data references
 */
export class CalculationResolver {
  constructor(
    private readonly fieldRefResolver: FieldRefResolver,
    private readonly baseDataResolver: BaseDataResolver,
    private readonly calculationRefResolver: CalculationRefResolver,
  ) {}

  /**
   * validates a calculation and returns a detailed calculation object with error information
   * @param calculation
   * @param rootCalculation (optional) only for internal use in recursive calls
   */
  public async validate(calculation: Calculation, rootCalculation?: Calculation): Promise<CalculationDetails> {
    const validatedCalculation = !rootCalculation
      ? CalculationHandler.addIds(ObjectUtil.clone<Calculation, CalculationDetails>(calculation))
      : ObjectUtil.clone<Calculation, CalculationDetails>(calculation);

    if (validatedCalculation.alias && rootCalculation) {
      if (
        CalculationHandler.findCalculationByAlias(rootCalculation, validatedCalculation.alias, validatedCalculation.id)
      ) {
        validatedCalculation.error = ResolveErrors.NON_UNIQUE_REF;
      }
    }

    validatedCalculation.params = await Promise.all(
      validatedCalculation.params.map(async (param) => {
        if (Is.inputValue(param)) {
          if (param.type !== DATA_VALUE_TYPE.NUMERIC && param.type !== DATA_VALUE_TYPE.DATE) {
            param.error = ResolveErrors.INVALID_DATA_TYPE;
          }
          return param;
        }

        if (Is.aliasValue(param)) {
          const rootCalculationHandler = new CalculationHandler(rootCalculation ?? calculation);
          if (!rootCalculationHandler.getAllowedAliases(calculation.alias).includes(param.name)) {
            param.error = ResolveErrors.INVALID_REFERENCE;
          }
          return param;
        }

        if (Is.fieldRef(param)) {
          const value = await this.resolveFieldRefValue(param).catch(() => {
            return undefined;
          });
          if (value) {
            if (Is.inputValue(value)) {
              if (value.type !== DATA_VALUE_TYPE.NUMERIC && value.type !== DATA_VALUE_TYPE.DATE) {
                param.error = ResolveErrors.INVALID_DATA_TYPE;
              }
            } else {
              param.error = ResolveErrors.INVALID_DATA_TYPE;
            }
          } else {
            param.error = ResolveErrors.INVALID_REFERENCE;
          }
          return param;
        }

        if (Is.baseDataValueRef(param)) {
          const baseDataValue = await this.baseDataResolver.resolve(param).catch(() => {
            return undefined;
          });
          const property = param.property;
          if (baseDataValue) {
            // check if the property exists in the base data
            const value = getSelectionDataValue(baseDataValue, property);
            if (!value) {
              param.error = ResolveErrors.INVALID_REFERENCE;
            } else if (value.type !== DATA_VALUE_TYPE.NUMERIC) {
              param.error = ResolveErrors.INVALID_DATA_TYPE;
            }
          } else {
            param.error = ResolveErrors.INVALID_REFERENCE;
          }
          return param;
        }

        if (Is.calculationRef(param)) {
          const calculation = await this.calculationRefResolver.resolve(param).catch(() => {
            return undefined;
          });
          if (!calculation) {
            param.error = ResolveErrors.INVALID_REFERENCE;
          }
          return param;
        }

        if (Is.calculation(param)) {
          return await this.validate(param, rootCalculation ?? validatedCalculation);
        }

        param.error = ResolveErrors.UNKNOWN;
        return param;
      }),
    );
    return {
      ...validatedCalculation,
      valid:
        !validatedCalculation.error &&
        validatedCalculation.params.every((param) => {
          if (Is.calculationDetails(param)) {
            return param.valid && !param.error;
          }
          return !param.error;
        }),
    };
  }

  /**
   * resolves all references in a calculation such as field references and base data references
   * @param calculation
   * @param relevantFields a list of field names which are relevant for the calculation this is helpful to ignore
   * fields which are hidden due conditions and should therefore not be used (-> default or empty value will be used)
   */
  public async resolve(calculation: Calculation, relevantFields: string[] = []): Promise<ValueCalculation> {
    const valueCalculation: ValueCalculation = ObjectUtil.clone(calculation) as ValueCalculation;
    for (let i = 0; i < calculation.params.length; i++) {
      const param = calculation.params[i];

      if (Is.value(param) || Is.aliasValue(param)) {
        continue;
      }

      if (Is.calculation(param) || Is.calculationRef(param)) {
        if (Is.calculation(param) && param.condition) {
          const isConditionValid = await this.isConditionValid(param.condition);
          if (!isConditionValid) {
            delete valueCalculation.params[i];
            continue;
          }
        }
        let calculation: Calculation | undefined = undefined;

        if (Is.calculationRef(param)) {
          calculation = await this.calculationRefResolver.resolve(param);
        } else {
          calculation = param;
        }

        if (!calculation) {
          delete valueCalculation.params[i];
          continue;
        }

        const subCalculation = await this.resolve(calculation, relevantFields);

        // remove empty sub calculations
        if (!subCalculation || subCalculation.params.length === 0) {
          delete valueCalculation.params[i];
          continue;
        }

        // remove sub calculation without relevant params
        const relevantSubParams = subCalculation.params.filter((dataValue) => {
          if (!Is.value(dataValue)) {
            return true;
          }
          return (dataValue as Value).type !== DATA_VALUE_TYPE.EMPTY;
        });
        if (relevantSubParams.length === 0) {
          delete valueCalculation.params[i];
          continue;
        }

        valueCalculation.params[i] = subCalculation;
        continue;
      }

      if (Is.fieldRef(param)) {
        const resolveFieldRef = await this.resolveFieldRefValue(param, relevantFields);
        if (resolveFieldRef) {
          valueCalculation.params[i] = resolveFieldRef;
          if (param.label) {
            valueCalculation.params[i].label = param.label;
          }
        }
        continue;
      }

      if (Is.baseDataValueRef(param)) {
        const baseDataValue = await this.baseDataResolver.resolve(param);
        if (baseDataValue) {
          const inputValue = getSelectionDataValue(baseDataValue, param.property);
          valueCalculation.params[i] = inputValue ?? Create.emptyInputValue();
        }
        continue;
      }

      throw new Error('Unsupported parameter cannot be resolved: ' + JSON.stringify(param));
    }

    valueCalculation.params = valueCalculation.params.filter((param) => param !== null);
    return valueCalculation;
  }

  /**
   * resolves a field reference
   * @param fieldRef
   * @param relevantFields if empty all fields are relevant, otherwise only the fields in the list are relevant
   * @private
   */
  private async resolveFieldRefValue(
    fieldRef: FieldRef,
    relevantFields: string[] = [],
  ): Promise<Calculation | InputValue> {
    if (relevantFields.length > 0) {
      // if a list of relevant fields is given, we only resolve the field reference if it is part of the list otherwise default value is used
      if (relevantFields.indexOf(fieldRef.name) === -1) {
        if (fieldRef.defaultValue) {
          return fieldRef.defaultValue;
        } else {
          return Create.inputValue(DATA_VALUE_TYPE.EMPTY);
        }
      }
    }
    const resolvedValue = await this.fieldRefResolver.resolve(fieldRef);
    if (Is.calculation(resolvedValue)) {
      return await this.resolve(resolvedValue, relevantFields);
    }

    if (Is.formValue(resolvedValue)) {
      if (Is.emptyFormValue(resolvedValue)) {
        if (fieldRef.defaultValue) {
          return fieldRef.defaultValue;
        } else {
          return Create.inputValue(DATA_VALUE_TYPE.EMPTY);
        }
      }
      if (Is.singleFormValue(resolvedValue)) {
        return resolvedValue.input;
      }
      if (Is.singleSelectionFormValue(resolvedValue)) {
        return DataUtil.selectionDataValue(resolvedValue.selection, fieldRef.property);
      }

      if (Is.multiSelectionFormValue(resolvedValue)) {
        return Create.calculation(DataUtil.selectionDataValues(resolvedValue.selection), CalcOperation.ADD);
      }
      if (Is.multiFormValue(resolvedValue)) {
        throw new Error(`Type "${resolvedValue.type}" is not (yet) supported for calculations`);
      }
      throw new Error(`Type "${resolvedValue.type}" is not supported for field references`);
    }

    throw new Error(`Failed to resolve field reference: ${fieldRef.name}`);
  }

  private async isConditionValid(condition: SimpleCondition): Promise<boolean> {
    try {
      if (condition.value1 === undefined || condition.value2 === undefined) {
        return false;
      }
      const givenFormValue = await this.fieldRefResolver.resolve(condition.value1);

      if (!Is.singleFormValue(givenFormValue)) {
        throw new Error(`Type "${givenFormValue.cls}" is not supported for conditions`);
      }
      let comparisonValue: InputValue | undefined = undefined;
      if (Is.inputValue(condition.value2)) {
        comparisonValue = condition.value2;
      }
      if (Is.fieldRef(condition.value2)) {
        const resolvedValue = await this.fieldRefResolver.resolve(condition.value2);
        if (Is.singleFormValue(resolvedValue)) {
          comparisonValue = resolvedValue.input;
        } else {
          throw new Error(`Type "${resolvedValue.cls}" is not supported for conditions`);
        }
      }

      return ConditionValidator.evaluateSingleValueCondition(givenFormValue.input, condition.op, comparisonValue);
    } catch (e) {
      return false;
    }
  }
}
