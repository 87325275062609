export class DateUtil {
  public static toDate(value: string): Date {
    return new Date(value);
  }

  /**
   * returns a unix timestamp in seconds
   */
  public static unixTimeStamp(): number {
    return Math.round(new Date().getTime() / 1000);
  }
}
