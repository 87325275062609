import { Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import {
  AsyncValidatorFn,
  ControlValueAccessor,
  FormBuilder,
  FormControl,
  FormGroup,
  NG_VALUE_ACCESSOR,
  ValidatorFn,
} from '@angular/forms';
import { ICON } from '@kfd/core';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'kfd-toggle-input-field',
  templateUrl: './toggle-input-field.component.html',
  styleUrl: './toggle-input-field.component.scss',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: ToggleInputFieldComponent,
    },
  ],
  standalone: false,
})
export class ToggleInputFieldComponent implements ControlValueAccessor, OnDestroy {
  @Input()
  public type = 'text';
  @Input()
  public minLength = 'text';
  @Input()
  public maxLength = 'text';
  @Input()
  public hint = '';
  @Input()
  public errorMsg = '';
  @Input()
  public icon: ICON | undefined;
  @Input()
  public invalid = false;

  @Output()
  public valueChange = new EventEmitter<string>();
  @Output()
  public validityChange = new EventEmitter<boolean>();
  @Output()
  public editModeChange = new EventEmitter<boolean>();

  protected readonly ICON = ICON;
  protected form: FormGroup | undefined;
  protected validatorErrorMsg: string | undefined;
  protected onChanged: CallableFunction | undefined;
  protected onTouched: CallableFunction | undefined;
  private destroy$ = new Subject<boolean>();

  constructor(private readonly formBuilder: FormBuilder) {
    this.updateForm();
  }

  private _value: string | undefined;

  get value(): string | undefined {
    return this._value;
  }

  @Input()
  public set value(value: string | undefined) {
    this._value = value;
    this._originValue = value;
    this.updateForm();
  }

  private _originValue: string | undefined;

  get originValue(): string | undefined {
    return this._originValue;
  }

  private _editMode = false;

  public get editMode(): boolean {
    return this._editMode;
  }

  private _validator: ValidatorFn[] = [];

  public get validator(): ValidatorFn[] {
    return this._validator;
  }

  @Input()
  public set validator(value: ValidatorFn[]) {
    this._validator = value;
    this.updateForm();
  }

  private _asyncValidator: AsyncValidatorFn | AsyncValidatorFn[] | undefined;

  @Input()
  public set asyncValidator(value: AsyncValidatorFn | AsyncValidatorFn[] | undefined) {
    this._asyncValidator = value;
    this.updateForm();
  }

  public ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  writeValue(value: string): void {
    this.value = value;
  }

  registerOnChange(onChange: CallableFunction): void {
    this.onChanged = onChange;
  }

  registerOnTouched(onChange: CallableFunction): void {
    this.onTouched = onChange;
  }

  setDisabledState?(isDisabled: boolean): void {
    if (isDisabled) {
      this.changeEditMode(false);
    }
  }

  /**
   * triggers form validation
   */
  public triggerValidation() {
    if (!this.form) {
      return;
    }
    Object.keys(this.form.controls).forEach((fieldName) => this.form?.controls[fieldName].markAsDirty());

    this.form.updateValueAndValidity();
    if (this.onTouched) {
      this.onTouched();
    }
  }

  protected resetValue(): void {
    this.value = this._originValue;
    if (this.onChanged) {
      this.onChanged(this.value ?? '');
    }
  }

  protected saveValue(): void {
    if (!this.form) {
      return;
    }
    const value = this.form.get<string>('value')?.value;
    this.value = value;

    if (this.onChanged) {
      this.onChanged(value ?? '');
    }

    this.triggerValidation();

    if (!this.form.valid) {
      return;
    }

    this.changeEditMode(false);

    this.valueChange.emit(value);
  }

  protected changeEditMode(mode: boolean) {
    if (this._editMode === mode) {
      return;
    }
    this._editMode = mode;
    this.editModeChange.emit(mode);
    if (mode && this.onTouched) {
      this.onTouched();
    }
  }

  protected updateForm(): void {
    this.form = this.formBuilder.group({
      value: new FormControl(this.value, this._validator, this._asyncValidator ?? null),
    });
    this.form
      .get('value')
      ?.valueChanges.pipe(takeUntil(this.destroy$))
      .subscribe((value) => {
        if (this.onChanged) {
          this.onChanged(value);
        }
      });
    this.form.statusChanges.pipe(takeUntil(this.destroy$)).subscribe((status) => {
      if (status === 'VALID') {
        this.validatorErrorMsg = undefined;
      } else {
        const field = this.form?.get('value');
        this.validatorErrorMsg = field && field.errors ? field.errors['message'] : this.errorMsg;
      }
      this.validityChange.emit(status === 'VALID');
    });
  }
}
