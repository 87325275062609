import { Create, ProjectCI } from '../dtos';

/**
 * constains helper functions without a specific context
 */

/**
 * converts projectCI settings to css variables
 */
export function projectCiToVariables(projectCI: ProjectCI | undefined) {
  if (!projectCI) {
    projectCI = Create.projectCI();
  }
  return {
    '--primary-color': projectCI.highlightColor,
    '--primary-color-light': getTintedColor(projectCI.highlightColor, 20),
    '--primary-color-lighter': getTintedColor(projectCI.highlightColor, 40),
    '--primary-color-dark': getTintedColor(projectCI.highlightColor, -20),
    '--primary-color-darker': getTintedColor(projectCI.highlightColor, -40),
    '--text-color-primary': projectCI.highlightTextColor,
    '--secondary-color': projectCI.mainColor,
    '--secondary-color-dark': getTintedColor(projectCI.mainColor, -20),
    '--secondary-color-darker': getTintedColor(projectCI.mainColor, -40),
    '--secondary-color-light': getTintedColor(projectCI.mainColor, 20),
    '--secondary-color-lighter': getTintedColor(projectCI.mainColor, 40),
    '--text-color-secondary': projectCI.mainTextColor,
    '--input-border': projectCI.mainColor,
    '--font-family': projectCI.fontName,
  };
}

/**
 * makes a hex color lighter/darker
 * @param color
 * @param percentage
 */
export function getTintedColor(color: string, percentage: number) {
  if (color.length > 6) {
    color = color.substring(1, color.length);
  }
  const rgb = parseInt(color, 16);
  let r: number = Math.abs(((rgb >> 16) & 0xff) + percentage);
  if (r > 255) r = r - (r - 255);
  let g: number = Math.abs(((rgb >> 8) & 0xff) + percentage);
  if (g > 255) g = g - (g - 255);
  let b: number = Math.abs((rgb & 0xff) + percentage);
  if (b > 255) b = b - (b - 255);
  r = Number(r < 0 || isNaN(r)) ? 0 : r > 255 ? 255 : r;
  g = Number(g < 0 || isNaN(g)) ? 0 : g > 255 ? 255 : g;
  b = Number(b < 0 || isNaN(b)) ? 0 : b > 255 ? 255 : b;

  let hexR = r.toString(16);
  if (hexR.length == 1) hexR = '0' + r;
  let hexG = g.toString(16);
  if (hexG.length == 1) hexG = '0' + g;
  let hexB = b.toString(16);
  if (hexB.length == 1) hexB = '0' + b;

  return '#' + hexR + hexG + hexB;
}
