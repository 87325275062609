import { GetEndpoint, Service } from './endpoints';
import { DocuStructure, FaqPage, WebPage } from '../dtos';

export enum CONTENT_EP {
  PAGE = 'page',
  DOCU = 'docu',
}

export enum CONTENT_REQ_PARAMS {
  PAGE_ID = 'pageId',
}

export const contentServiceEP = {
  page: {
    getSingle: new GetEndpoint<WebPage>(
      Service.CONTENT_SERVICE,
      `/${CONTENT_EP.PAGE}/:${CONTENT_REQ_PARAMS.PAGE_ID}`,
      null as unknown as WebPage,
    ),
  },
  docu: {
    structure: new GetEndpoint<DocuStructure>(
      Service.CONTENT_SERVICE,
      `/${CONTENT_EP.DOCU}/structure`,
      null as unknown as DocuStructure,
    ),
    page: new GetEndpoint<WebPage>(
      Service.CONTENT_SERVICE,
      `/${CONTENT_EP.DOCU}/page/:${CONTENT_REQ_PARAMS.PAGE_ID}`,
      null as unknown as WebPage,
    ),
  },
  faq: new GetEndpoint<FaqPage>(Service.CONTENT_SERVICE, `/${CONTENT_EP.DOCU}/faq`, null as unknown as FaqPage),
};
