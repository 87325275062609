import { Pipe, PipeTransform } from '@angular/core';
import { StringUtil } from '@kfd/core';

/**
 * usage
 * [title]="aStringValue | saveString"
 */
@Pipe({
  name: 'saveString',
  standalone: true,
})
export class SaveStringPipe implements PipeTransform {
  transform(inputValue: string): string {
    return StringUtil.toSaveString(inputValue);
  }
}
