// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type ObjectType = Record<any, any> | object | undefined;

export class ObjectUtil {
  /**
   * returns all keys from object a which are not in object b
   *
   */
  public static keyDiff(a: ObjectType, b: ObjectType): ObjectType {
    const diff = {};
    for (const key in a) {
      if (!b || !(key in b)) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        diff[key] = a[key];
      }
    }
    return diff;
  }

  /**
   * filters undefined, null and empty object "{}" values (from root level only)
   * @param obj
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public static filterUnset<T extends Record<any, any>>(obj: T): T {
    Object.keys(obj).forEach(function (key) {
      if (obj[key] === undefined || obj[key] === null) {
        delete obj[key];
      }
    });
    return obj;
  }

  /**
   * filters undefined, null for all properties in nested objects
   * @param obj
   * @param filterEmptyValues
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public static filterUnsetNested<T extends Record<any, any>>(obj: T, filterEmptyValues = false): T {
    Object.keys(obj).forEach(function (key) {
      if (obj[key] === undefined || obj[key] === null) {
        delete obj[key];
      }
      if (filterEmptyValues) {
        if (Array.isArray(obj[key]) && obj[key].length === 0) {
          delete obj[key];
        }
        if (typeof obj[key] === 'object' && Object.keys(obj[key]).length === 0 && obj[key].constructor === Object) {
          delete obj[key];
        }
        if (typeof obj[key] === 'string' && (obj[key] as string).length === 0) {
          delete obj[key];
        }
      } else if (typeof obj[key] === 'object') {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        obj[key] = ObjectUtil.filterUnsetNested(obj[key]);
      } else if (Array.isArray(obj[key])) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        obj[key] = obj[key].map((item) => ObjectUtil.filterUnsetNested(item));
      }
    });
    return obj;
  }

  /**
   * filters undefined, null and empty values like "" "{}" values
   * @param obj
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public static filterUnsetAndEmpty<T extends Record<any, any>>(obj: T): T {
    return this.filterUnsetNested(obj, true);
  }

  /**
   * returns the value for a given key
   * supports nested keys (separated with ".")
   *
   * usage: ObjectUtil.getValue(obj, "foo.bar")
   */
  public static getValue(
    inputObj: Record<string, unknown> | object | undefined,
    key: string | undefined,
  ): unknown | undefined {
    if (inputObj === undefined || key === undefined) {
      return undefined;
    }
    const keyParts = key.split('.');
    if (keyParts.length === 1) {
      if (Object.prototype.hasOwnProperty.call(inputObj, keyParts[0])) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        return inputObj[keyParts[0]];
      } else {
        return;
      }
    }

    const firstKey = keyParts.shift();
    if (firstKey === undefined) {
      return undefined;
    }
    if (Object.prototype.hasOwnProperty.call(inputObj, firstKey)) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      return ObjectUtil.getValue(inputObj[firstKey] as Record<string, unknown>, keyParts.join('.'));
    }
    return undefined;
  }

  /**
   * sets key to a given value
   * supports nested keys (separated with ".")
   * if value is undefined it will remove the key
   *
   * usage: ObjectUtil.setValue(obj, "foo.bar", "new-value")
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public static setValue<T extends Record<any, any>>(
    inputObj: unknown,
    key: string,
    value: unknown,
    cleanUp = false,
  ): T {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const objToModify = ObjectUtil.clone<any>(inputObj);
    const keyParts = key.split('.');
    if (keyParts.length === 1) {
      objToModify[keyParts[0]] = value;
      if (cleanUp && objToModify[keyParts[0]] === undefined) {
        delete objToModify[keyParts[0]];
      }
      return objToModify;
    }
    const firstKey = keyParts.shift();
    if (firstKey === undefined) {
      return objToModify;
    }
    if (objToModify[firstKey] === undefined) {
      objToModify[firstKey] = {};
    }
    objToModify[firstKey] = ObjectUtil.setValue(objToModify[firstKey], keyParts.join('.'), value, cleanUp);
    if (cleanUp && Object.values(objToModify[firstKey]).length === 0) {
      delete objToModify[firstKey];
    }
    return objToModify;
  }

  /**
   * removes given keys (and their values) in any depth of the object
   * usage: ObjectUtil.removeKeys(obj, ['_id'])
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public static removeKeys<T extends Record<any, any>>(
    object: T,
    keys: string[],
    removeEmptyObjects = true,
    includeArrayValues = true,
  ): T {
    const obj = JSON.parse(JSON.stringify(object));

    for (const key of keys) {
      if (obj[key] !== undefined) {
        delete obj[key];
      }
    }

    for (const item in obj) {
      if (Array.isArray(obj[item])) {
        for (const i in obj[item]) {
          if (typeof obj[item][i] === 'object') {
            obj[item][i] = ObjectUtil.removeKeys(obj[item][i], keys);
          } else if (includeArrayValues) {
            if (keys.indexOf(obj[item][i]) >= 0) {
              delete obj[item][i];
            }
          }
        }
        obj[item] = obj[item].filter((val: string) => val !== null);
      } else if (typeof obj[item] === 'object') {
        obj[item] = ObjectUtil.removeKeys(obj[item], keys);
        if (removeEmptyObjects) {
          if (Object.keys(obj[item]).length === 0) {
            delete obj[item];
          }
        }
      }
    }
    return obj;
  }

  /**
   * calls the callback function for each value in nested objects
   */
  public static eachValue<T = unknown>(object: T, cbFn: CallableFunction): T {
    for (const objectKey in object) {
      if (Array.isArray(object[objectKey])) {
        for (const i in object[objectKey]) {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          if (typeof object[objectKey][i] === 'object') {
            ObjectUtil.eachValue(object[objectKey], cbFn);
          } else {
            object[objectKey] = cbFn(object[objectKey]);
          }
        }
      } else if (typeof object[objectKey] === 'object') {
        ObjectUtil.eachValue(object[objectKey], cbFn);
      } else {
        object[objectKey] = cbFn(object[objectKey]);
      }
    }
    return object;
  }

  /**
   * creates a copy without references from an existing object by using stringification
   * does not return instances
   */
  public static clone<T, U extends T = T>(obj: T): U {
    if (typeof obj !== 'object') {
      throw new Error('ObjectUtil.clone: object is not an object: ' + JSON.stringify(obj));
    }
    return JSON.parse(JSON.stringify(obj)) as U;
  }

  /**
   * returns true if the objects are deeply equal
   * @param a
   * @param b
   */
  public static equals(a: ObjectType, b: ObjectType): boolean {
    return JSON.stringify(a) === JSON.stringify(b);
  }
}
