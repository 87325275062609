import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { PrintValue } from '@kfd/core';

@Component({
  selector: 'kfd-value-list',
  templateUrl: './value-list.component.html',
  styleUrls: ['./value-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class ValueListComponent {
  @Input()
  public printValueList: PrintValue[] | undefined;
}
