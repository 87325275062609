<ng-container *ngIf="page">
  @if (showTitle && !sectionId) {
    <h1>{{ page.title }}</h1>
  }

  @if (!sectionId && showToc === true && page.content.length > 3) {
    <div class="page-structure">
      <div class="ml-2">Auf dieser Seite:</div>
      <ol class="m-2">
        <ng-container *ngFor="let content of page.content">
          <a [fragment]="content.id" [routerLink]="'.'">
            <li *ngIf="content.title">{{ content.title }}</li>
          </a>
        </ng-container>
      </ol>
    </div>
  }

  <div class="page-content">
    @for (content of page.content; track content.id) {
      @if (!sectionId || sectionId === content.id) {
        @switch (content.type) {
          @case ('content_section') {
            @if (content.title) {
              <h2 [attr.id]="content.id">
                <i class="pi pi-chevron-right"></i>
                {{ content.title }}
                @if (showAnchorLinks) {
                  <a [fragment]="content.id" [routerLink]="'.'"><i class="pi pi-link"></i></a>
                }
              </h2>
            }
            <div [innerHTML]="$any(content.data).content || ''"></div>
          }
          @case ('html') {
            @if (content.data) {
              <div [innerHTML]="content.data"></div>
            }
          }
          @case ('cffg') {
            <!--  <kfd-configuration-->
              <!--    [configuration]="data.configuration"-->
              <!--    [project]="data.project"-->
              <!--    [seamless]="data.seamless ? data.seamless : false"-->
              <!--  >-->
              <!--  </kfd-configuration>-->
          }
        }
      }
    }
  </div>
</ng-container>
