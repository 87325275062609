import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { BreakpointObserver } from '@angular/cdk/layout';
import { WebScreenSize } from '../common';

const BREAK_POINTS = {
  [WebScreenSize.XL]: {
    size: WebScreenSize.XL,
    min: 1200,
  },

  [WebScreenSize.LG]: {
    size: WebScreenSize.LG,
    min: 993,
  },
  [WebScreenSize.MD]: {
    size: WebScreenSize.MD,
    min: 768,
  },
  [WebScreenSize.XS]: {
    size: WebScreenSize.XS,
    min: 0,
  },
};

const BREAK_POINT_LIST = [
  BREAK_POINTS[WebScreenSize.XL],
  BREAK_POINTS[WebScreenSize.LG],
  BREAK_POINTS[WebScreenSize.MD],
];

function minWidth(number: number): string {
  return `(min-width: ${number}px)`;
}

@Injectable({
  providedIn: 'root',
})
export class BreakpointService {
  constructor(private readonly breakpointObserver: BreakpointObserver) {}

  public currentSize(): WebScreenSize {
    for (const breakPoint of BREAK_POINT_LIST) {
      if (this.breakpointObserver.isMatched(minWidth(breakPoint.min))) {
        return breakPoint.size;
      }
    }
    return WebScreenSize.XS;
  }

  public change(): Observable<WebScreenSize> {
    return this.breakpointObserver.observe(BREAK_POINT_LIST.map((v) => minWidth(v.min)).join(',')).pipe(
      map((result) => {
        for (const breakPoint of BREAK_POINT_LIST) {
          if (result.breakpoints[`(min-width: ${breakPoint.min}px)`]) {
            return breakPoint.size;
          }
        }
        return WebScreenSize.XS;
      }),
    );
  }

  public isMd(): Observable<boolean> {
    return this.breakpointObserver
      .observe(['(min-width: 768px)'])
      .pipe(map((result) => result.breakpoints['(min-width: 768px)']));
  }

  public isLg(): Observable<boolean> {
    return this.breakpointObserver
      .observe(['(min-width: 992px)'])
      .pipe(map((result) => result.breakpoints['(min-width: 992px)']));
  }
}
