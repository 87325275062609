@if (viewData$ | async; as viewData) {
  @if (viewData.signInState === 'signedIn') {
    <p-message
      class="block mt-2"
      data-automationId="msg-login-success"
      severity="success"
      text="Sie haben sich erfolgreich angemeldet"
    ></p-message>
  } @else {
    <div data-automationId="login-form">
      <form (keyup.enter)="signIn()" (ngSubmit)="signIn()">
        <div class="p-fluid">
          <div class="p-field">
            <label>E-Mail</label>
            <input [(ngModel)]="email" data-automationId="input-email" name="inputEmail" pInputText type="text" />
          </div>
          <div class="p-field">
            <label>Passwort</label>
            <input
              [(ngModel)]="password"
              data-automationId="input-password"
              name="inputPassword"
              pInputText
              type="password"
            />
          </div>
          @switch (viewData.signInState) {
            @case ('error') {
              <p-message
                class="block mt-4"
                data-automationId="msg-login-error"
                severity="error"
                text="Die Anmeldung ist momentan nicht möglich. Bitte versuchen Sie es später erneut."
              ></p-message>
            }
            @case (ApiClientErrors.USER_INACTIVE) {
              <kfd-action-message
                class="block mt-4"
                data-automationId="msg-login-inactive"
                severity="warn"
                text="Konto inaktiv"
                info="Ihr Konto ist inaktiv. Bitte überprüfen Sie Ihre E-Mails und aktivieren Sie Ihr Konto."
                [actionBtnIcon]="ICON.NEXT"
                [link]="COMMON_WEB_APP_ROUTES.user.activation.root"
                actionBtnTooltip="Konto aktivieren"
              ></kfd-action-message>
            }
            @case (ApiClientErrors.USER_DISABLED) {
              <kfd-action-message
                class="block mt-4"
                data-automationId="msg-login-disabled"
                severity="warn"
                text="Konto deaktiviert"
                info="Ihr Konto wurde deaktiviert. Bitte wenden Sie sich an den Support."
              ></kfd-action-message>
            }
            @case (ApiClientErrors.USER_NOT_FOUND) {
              <kfd-action-message
                class="block mt-4"
                data-automationId="msg-login-failed"
                severity="warn"
                text="Anmeldung fehlgeschlagen, bitte überprüfen Sie Ihre Eingaben."
                info="Achten Sie bei Ihrem Passwort auf Groß- und Kleinschreibung. Sollten Sie Ihr Passwort vergessen haben, können Sie es zurücksetzen."
                [actionBtnIcon]="ICON.RESET"
                [link]="COMMON_WEB_APP_ROUTES.user.passwordReset"
                actionBtnTooltip="Passwort zurücksetzen"
              ></kfd-action-message>
            }
          }

          <div class="flex justify-content-between mt-4">
            <div></div>
            <p-button
              data-automationId="submit-sign-in-form-button"
              severity="secondary"
              styleClass="min-width"
              label="Anmelden"
              icon="pi {{ICON.NEXT}}"
              (click)="signIn()"
            ></p-button>
          </div>
        </div>
      </form>
    </div>

    <div class="mt-4 flex align-items-center">
      <p-button
        icon="pi {{ ICON.PROFILE }}"
        class="mr-2"
        label="Neu registrieren"
        data-automationId="sign-up-btn"
        [rounded]="true"
        [text]="true"
        (click)="pageChange.emit(COMMON_WEB_APP_ROUTES.user.registration)"
        [routerLink]="COMMON_WEB_APP_ROUTES.user.registration"
      >
      </p-button>
      <p-button
        icon="pi {{ ICON.RESET }}"
        class="mr-2"
        label="Passwort vergessen"
        [rounded]="true"
        [text]="true"
        (click)="pageChange.emit(COMMON_WEB_APP_ROUTES.user.passwordReset)"
        [routerLink]="COMMON_WEB_APP_ROUTES.user.passwordReset"
      >
      </p-button>
    </div>

    <div class="third-party-login mt-4">
      <div class="divider">oder</div>
      <div class="flex mt-4">
        <p-button
          icon="pi {{ICON.GOOGLE}}"
          label="Mit Google anmelden"
          severity="secondary"
          class="flex-1"
          styleClass="full-width"
          (click)="redirectToGoogle()"
        ></p-button>
        <!--      <p-button-->
        <!--        icon="pi {{ICON.GOOGLE}}"-->
        <!--        label="Mit Google anmelden"-->
        <!--        severity="secondary"-->
        <!--        class="flex-1 ml-1"-->
        <!--        styleClass="full-width"-->
        <!--      ></p-button>-->
      </div>
    </div>
  }

}
