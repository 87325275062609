import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ApiClientErrors, COMMON_WEB_APP_ROUTES, ICON, unvTimeout } from '@kfd/core';
import { BehaviorSubject, Observable, Subject, takeUntil } from 'rxjs';
import { AuthService } from '../../services/auth.service';
import { ClientCodeError } from '../../common/exceptions';
import { map } from 'rxjs/operators';

@Component({
  selector: 'kfd-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.scss'],
  standalone: false,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SignInComponent implements OnInit, OnDestroy {
  @Output()
  public loginSuccess = new EventEmitter<boolean>();
  @Output()
  public pageChange = new EventEmitter<string>();
  @Input()
  public emitLogin = true;
  protected readonly COMMON_WEB_APP_ROUTES = COMMON_WEB_APP_ROUTES;
  protected readonly ICON = ICON;
  protected email = '';
  protected password = '';
  protected readonly ApiClientErrors = ApiClientErrors;
  protected viewData$:
    | Observable<{
        signInState?: 'signedOut' | 'signedIn' | 'loading' | 'error' | ApiClientErrors;
      }>
    | undefined;
  private destroy$ = new Subject<boolean>();
  private signInState$ = new BehaviorSubject<'signedOut' | 'signedIn' | 'loading' | 'error' | ApiClientErrors>(
    'signedOut',
  );

  constructor(private readonly authService: AuthService) {}

  public ngOnInit(): void {
    this.viewData$ = this.signInState$.pipe(
      map((signInState) => ({
        signInState,
      })),
    );
    // this.signInSuccess = false;
    this.authService
      .onSignInChange()
      .pipe(takeUntil(this.destroy$))
      .subscribe((signedIn) => {
        this.signInState$.next(signedIn ? 'signedIn' : 'signedOut');
        if (this.emitLogin && signedIn) {
          //use timeout to enable other components render first
          unvTimeout(() => {
            this.loginSuccess.emit(signedIn);
          }, 500);
        }
      });
  }

  public ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  redirectToGoogle() {
    this.authService.googleSignIn();
  }

  signIn(): void {
    this.signInState$.next('loading');
    this.authService.signIn(this.email, this.password, this.emitLogin).subscribe({
      next: () => {
        this.signInState$.next('signedIn');
        unvTimeout(() => {
          this.loginSuccess.emit(true);
        }, 1000);
      },
      error: (e) => {
        if (e instanceof ClientCodeError) {
          this.signInState$.next(e.code);
        } else {
          this.signInState$.next('error');
        }
      },
    });
  }
}
