import { Inject, Injectable, OnDestroy, PLATFORM_ID } from '@angular/core';
import { BehaviorSubject, filter, Observable, of, Subject, takeUntil, zip } from 'rxjs';
import {
  authServiceEP,
  AuthToken,
  CACHE_L,
  clearUnvTimeout,
  DateUtil,
  Id,
  InsertResult,
  JwtUser,
  RequiredActions,
  SelectOption,
  unvInterval,
  UnvTimeoutValue,
  User,
  UserInfo,
} from '@kfd/core';
import { catchError, map, tap } from 'rxjs/operators';
import { WebEndpointService } from './web-endpoint.service';
import { CachedWebEndpointService } from './cached-web-endpoint.service';
import { isPlatformServer } from '@angular/common';
import { ConfigService, WEB_CONFIG_SERVICE } from './config.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService implements OnDestroy {
  public signOutReason: 'user' | 'time' | undefined;
  private destroy$ = new Subject<boolean>();
  private sessionTimeoutTime: number;
  // timestamp of session start / refresh
  private sessionStartTime: number | undefined;
  private lastSignInState: boolean | undefined = undefined;
  private signInChange = new BehaviorSubject<boolean>(false);
  private sessionTimeoutInterval: UnvTimeoutValue | undefined;
  private readonly cwes: CachedWebEndpointService;
  private readonly isSSR: boolean;

  constructor(
    readonly webEndpointService: WebEndpointService,
    @Inject(PLATFORM_ID) platformId: object,
    @Inject(WEB_CONFIG_SERVICE) private readonly configService: ConfigService,
  ) {
    this.isSSR = isPlatformServer(platformId);
    this.cwes = new CachedWebEndpointService(webEndpointService, CACHE_L, !this.isSSR);
    this.sessionTimeoutTime = this.configService.sessionTimeout;
  }

  public get isLoggedIn(): boolean {
    const state = this.loggedInState();
    return state === 'loggedIn';
  }

  private set isLoggedIn(isLoggedIn: boolean) {
    if (isLoggedIn) {
      this.sessionStartTime = DateUtil.unixTimeStamp();
      this.startSessionTimeoutCheck();
      this.signOutReason = undefined;
    } else {
      this.sessionStartTime = undefined;
      this.stopSessionTimeoutCheck();
    }
  }

  private _csrfToken = '';

  public get csrfToken(): string {
    return this._csrfToken;
  }

  public ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  healthy(): Observable<boolean> {
    return this.cwes.get(authServiceEP.healthy);
  }

  public onSignInChange(): Observable<boolean> {
    return this.signInChange.asObservable();
    // return new Observable((subscriber) => {
    //   this.signInChange.subscribe((signInChange) => {
    //     subscriber.next(signInChange);
    //   });
    //   // if (this.isLoggedIn) {
    //   //   subscriber.next(true);
    //   // } else {
    //   //
    //   //   this.currentUser()
    //   //     .pipe(first())
    //   //     .subscribe(() => {
    //   //       // nothing to do because currentUser will fire signInChange
    //   //       // subscriber.next(user !== undefined);
    //   //     });
    //   // }
    // });
  }

  userExists(email: string): Observable<typeof authServiceEP.auth.userExists.response> {
    return this.cwes.get(authServiceEP.auth.userExists, [email]);
  }

  /**
   * redirects to google login
   */
  public googleSignIn(): void {
    window.location.replace(this.webEndpointService.url(authServiceEP.auth.google.login));
  }

  public signIn(email: string, password: string, emit = true): Observable<void> {
    return this.cwes
      .post(
        authServiceEP.auth.login,
        [],
        {
          username: email,
          password,
        },
        {
          withCredentials: true,
        },
      )
      .pipe(
        tap(() => {
          if (!this.isLoggedIn) {
            if (emit) {
              this.emitSignInChange(true);
            }
            this.isLoggedIn = true;
          }
        }),
      );
  }

  public signUp(email: string, password: string, name: string, privacyAccepted: boolean): Observable<InsertResult> {
    return this.cwes.post(authServiceEP.auth.register, [], {
      email,
      password,
      name,
      privacyAccepted,
    });
  }

  public resendActivationMail(
    email: string,
    password: string,
  ): Observable<typeof authServiceEP.auth.resendActivationMail.response> {
    return this.cwes.post(authServiceEP.auth.resendActivationMail, [], {
      email,
      password,
    });
  }

  refreshToken(): Observable<AuthToken> {
    //set isLoggedIn to true to set new session start time
    return this.cwes.post(authServiceEP.auth.refresh, []).pipe(tap(() => (this.isLoggedIn = true)));
  }

  updateCsrfToken(): Observable<string> {
    return this.cwes.get(authServiceEP.auth.csrfToken).pipe(tap((csrfToken) => (this._csrfToken = csrfToken)));
  }

  currentUser(useCache = true): Observable<JwtUser | undefined> {
    if (this.isSSR) {
      return of(undefined);
    }
    return this.cwes.get(authServiceEP.auth.currentUser.user, [], { useCache }).pipe(
      takeUntil(this.destroy$),
      catchError(() => {
        if (this.isLoggedIn) {
          this.emitSignInChange(false);
          this.isLoggedIn = false;
        }
        return of(undefined);
      }),
      map((user) => {
        if (user) {
          if (!this.isLoggedIn) {
            this.isLoggedIn = true;
          }
          this.emitSignInChange(true);
          return user;
        } else {
          if (this.isLoggedIn) {
            this.isLoggedIn = false;
          }
          this.emitSignInChange(false);
          return undefined;
        }
      }),
    );
  }

  profile(useCache = true): Observable<User> {
    return this.cwes.get(authServiceEP.auth.userProfile.details, [], { useCache });
  }

  requiredUserActions(): Observable<RequiredActions> {
    return this.cwes.get(authServiceEP.auth.currentUser.requiredActions);
  }

  emailValidate(email: string, code: string): Observable<boolean> {
    return this.cwes.post(authServiceEP.auth.validateEmailByCode, [email, code]);
  }

  changeProfile(name: string): Observable<User> {
    return this.cwes.put(authServiceEP.auth.userProfile.change, [], { name });
  }

  changeEmail(email: string): Observable<boolean> {
    return this.cwes.put(authServiceEP.auth.userProfile.changeEmail, [], { email });
  }

  passwordReset(email: string): Observable<boolean> {
    return this.cwes.post(authServiceEP.auth.currentUser.passwordReset, [], { email });
  }

  setPassword(email: string, code: string, password: string): Observable<boolean> {
    return this.cwes.post(authServiceEP.auth.currentUser.setPassword, [], {
      email,
      code,
      password,
    });
  }

  // updatePassword(oldPassword: string, newPassword: string): Observable<boolean> {
  //   return this.cwes.post(authServiceEP.auth.currentUser.updatePassword, [], {
  //     old: oldPassword,
  //     new: newPassword,
  //   });
  // }

  specificUserInfo(userId: Id | undefined): Observable<UserInfo | undefined>;
  specificUserInfo(userId: Id, useCache: boolean): Observable<UserInfo>;
  specificUserInfo(userId: Id | undefined, useCache = true): Observable<UserInfo | undefined> {
    if (userId === undefined) {
      return of(undefined);
    }
    return this.cwes.get(authServiceEP.auth.users.info, [userId.toString()], { useCache });
  }

  decorateUserNames(list: SelectOption[]): Observable<SelectOption[]> {
    if (list.length === 0) {
      return of([]);
    }
    const obs = list.map((option) =>
      this.specificUserInfo('' + option.value).pipe(
        filter((user) => user !== undefined),
        map((user) => ({ value: user?.id, label: user?.name })),
      ),
    );
    return zip(...obs) as Observable<SelectOption[]>;
  }

  acceptPrivacy(): Observable<boolean> {
    return this.cwes.post(authServiceEP.auth.currentUser.acceptPrivacy);
  }

  public signOut(): Observable<void> {
    return this.cwes.post(authServiceEP.auth.logout).pipe(
      tap(() => {
        this.signOutReason = 'user';
        this.handleSignOut();
      }),
    );
  }

  private handleSignOut(): void {
    this.isLoggedIn = false;
    this.emitSignInChange(false);
    this.stopSessionTimeoutCheck();
  }

  private stopSessionTimeoutCheck(): void {
    clearUnvTimeout(this.sessionTimeoutInterval);
  }

  private emitSignInChange(signedInState: boolean): void {
    if (this.lastSignInState === signedInState) {
      return;
    }
    this.lastSignInState = signedInState;
    // this.signInChange.emit(signedInState);
    this.signInChange.next(signedInState);
  }

  private startSessionTimeoutCheck(): void {
    this.stopSessionTimeoutCheck();
    this.sessionTimeoutInterval = unvInterval((): void => {
      const state = this.loggedInState();
      if (state === 'manual') {
        this.signOutReason = undefined;
        this.handleSignOut();
        return;
      } else if (state === 'time') {
        this.signOutReason = 'time';
        this.handleSignOut();
      }
    }, 5000);
  }

  private loggedInState(): 'loggedIn' | 'manual' | 'time' {
    if (!this.sessionStartTime) {
      return 'manual';
    }
    const minutesLeft = Math.ceil((DateUtil.unixTimeStamp() - this.sessionStartTime) / 60);
    return minutesLeft < this.sessionTimeoutTime ? `loggedIn` : 'time';
  }
}
