<div class="flex flex-column">
  <div class="values">
    @if (values && values.length > 0) {
      @for (value of values; track value.value) {
        <div class="value-row flex flex-row">
          <div class="flex-1 flex align-items-center overflow-hidden">
            <span class="text-overflow-ellipsis">
              <strong class="font-medium">{{ value.label }}: </strong>
              <i class="font-italic">{{ valueLabel(value) }}</i>
            </span>
          </div>
          <div>
            @if (!disabled) {
              <p-button
                (click)="editValue(value.value)"
                icon="pi {{ ICON.EDIT }}"
                pTooltip="Wert bearbeiten"
                [text]="true"
              ></p-button>
            }
          </div>
          @if (value.value === valueToEdit) {
            <div class="edit-overlay p-fluid">
              <div class="p-field">
                <label>Bezeichnung</label>
                <input pInputText type="text" #labelInput [ngModel]="value.label" />
              </div>
              <div class="p-field">
                <label>Wert</label>
                <p-select
                  #valueSelection
                  [options]="valueOptions"
                  [ngModel]="value.value"
                  optionValue="identifier"
                ></p-select>
              </div>

              <div class="flex justify-content-between">
                <p-button
                  (click)="removeValue(value.value)"
                  icon="pi {{ ICON.DELETE }}"
                  pTooltip="Wert entfernen"
                  [text]="true"
                ></p-button>
                <div>
                  <p-button
                    (click)="cancelEdit()"
                    icon="pi {{ ICON.DECLINE }}"
                    pTooltip="Bearbeiten abbrechen"
                    tooltipPosition="left"
                    [text]="true"
                  ></p-button>
                  <p-button
                    (click)="updateValue(labelInput.value, valueSelection.value)"
                    icon="pi {{ ICON.ACCEPT }}"
                    pTooltip="Änderungen übernehmen"
                    tooltipPosition="left"
                    [text]="true"
                  ></p-button>
                </div>
              </div>
            </div>
          }
        </div>
      }
    } @else {
      <div class="text-center mt-1">
        <i>- Keine Werte -</i>
      </div>
    }
  </div>

  @if (!disabled) {
    <div class="flex justify-content-end">
      <p-button
        [disabled]="!valueOptions || valueOptions.length - 1 === values.length"
        (click)="addValue()"
        [icon]="ICON.ADD"
        pTooltip="Wert hinzufügen"
        [text]="true"
        [rounded]="true"
      ></p-button>
    </div>
  }
</div>
