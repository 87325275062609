import {
  BASE_META_SCHEMA,
  baseDataSchema,
  baseDataTemplateSchema,
  CONFIGURATION_IDENTIFIER,
  CUSTOMER_REQUEST_FILTER_SAVED,
  FORM_VALUE_MAP,
  genericProps,
  genericRequired,
  PROJECT_CI,
  PROJECT_INFO,
  PROJECT_LICENCE,
  PROJECT_META_SCHEMA,
  REQUEST_STATUS,
  REQUEST_STATUS_LOG,
  USER_SCOPE,
} from './schemas';
import { SchemaUtil } from './schema-util';
import { AssetType, CLS, NotificationScope, NotificationSeverity, SETTINGS_KEYS, VALIDATION_CHECK_KEY } from '../dtos';
import {
  CONFIGURATION_SHORT_CODE,
  PATTERN,
  PROP_EMAIL,
  PROP_MONGO_ID,
  PROP_NAME,
  PROP_VERIFICATION_CODE,
  REQUEST_STATUS_CODE_VALUE,
  SIMPLE_TEXT,
} from './schema.global';

export const ASSET_DB_SCHEMA = SchemaUtil.object([...genericRequired(), 'type', 'size', 'blobId', 'meta'], {
  ...genericProps(),
  size: SchemaUtil.numeric({ minimum: 0 }),
  type: SchemaUtil.enum(AssetType),
  blobId: PROP_MONGO_ID,
  meta: PROJECT_META_SCHEMA,
});

export const BLOB_DB_SCHEMA = SchemaUtil.object(['_id', 'data'], {
  _id: PROP_MONGO_ID,
  // data: SchemaUtil.string({ pattern: PATTERN.BASE64, maxLength: 1000000 }),
  data: SchemaUtil.string({ maxLength: 1500000 }),
});

export const VALIDATION_CHECK = SchemaUtil.object(['key', 'date', 'valid'], {
  key: SchemaUtil.enum(VALIDATION_CHECK_KEY),
  date: SchemaUtil.date(),
  valid: SchemaUtil.bool(),
});

/**
 * @see CmsProject
 */
export const PROJECT_DB_SCHEMA = SchemaUtil.object(['_id', 'cls', 'name', 'label', 'meta', 'users', 'licence'], {
  _id: SchemaUtil.objectId(),
  cls: SchemaUtil.enum([CLS.PROJECT]),
  name: PROP_NAME,
  label: SIMPLE_TEXT,
  info: PROJECT_INFO,
  infoChanges: PROJECT_INFO,
  ci: PROJECT_CI,
  infoVerified: SchemaUtil.date(),
  pages: SchemaUtil.array(SIMPLE_TEXT),
  users: SchemaUtil.array(USER_SCOPE),
  licence: PROJECT_LICENCE,
  orderedLicence: SchemaUtil.object(['licence', 'date'], {
    licence: PROJECT_LICENCE,
    date: SchemaUtil.date(),
    approved: SchemaUtil.date(),
    approvedBy: SchemaUtil.objectId(),
  }),
  requestStatus: SchemaUtil.array(REQUEST_STATUS),
  meta: BASE_META_SCHEMA,
  baseDataTemplates: SchemaUtil.array(baseDataTemplateSchema()),
  validation: SchemaUtil.object(['date', 'valid', 'checks'], {
    date: SchemaUtil.date(),
    valid: SchemaUtil.bool(),
    checks: SchemaUtil.array(VALIDATION_CHECK),
  }),
  disabled: SchemaUtil.object(['user', 'reason', 'date'], {
    date: SchemaUtil.date(),
    reason: SchemaUtil.string(),
    user: SchemaUtil.objectId(),
  }),
  deletionFlag: SchemaUtil.object(['user', 'reason', 'date'], {
    date: SchemaUtil.date(),
    reason: SchemaUtil.string(),
    user: SchemaUtil.objectId(),
  }),
});

/**
 * @see CustomerRequest
 */
export const CUSTOMER_REQUEST_DB_SCHEMA = SchemaUtil.object(
  [
    '_id',
    'projectId',
    'code',
    'configuration',
    'formValueMap',
    'customerCode',
    'currentState',
    'createdDate',
    'updatedDate',
    'statusLog',
  ],
  {
    _id: PROP_MONGO_ID,
    projectId: PROP_MONGO_ID,
    code: SchemaUtil.object(['cfg', 'number'], {
      cfg: CONFIGURATION_SHORT_CODE,
      number: SchemaUtil.numeric(),
    }),
    configuration: CONFIGURATION_IDENTIFIER,
    title: SchemaUtil.array(SchemaUtil.string()),
    formValueMap: FORM_VALUE_MAP,
    valueMapChanges: FORM_VALUE_MAP,
    customerCode: SchemaUtil.string({
      pattern: PATTERN.CUSTOMER_CODE,
    }),
    currentState: REQUEST_STATUS_CODE_VALUE,
    currentUser: SchemaUtil.objectId(),
    userList: SchemaUtil.array(SchemaUtil.objectId()),
    createdDate: SchemaUtil.date(),
    updatedDate: SchemaUtil.date(),
    statusLog: SchemaUtil.array(REQUEST_STATUS_LOG),
    customerSettings: SchemaUtil.object(undefined, {
      enableEmailNotifications: SchemaUtil.bool(),
      emailVerification: SchemaUtil.object(['email', 'date', 'code'], {
        email: PROP_EMAIL,
        date: SchemaUtil.date(),
        code: PROP_VERIFICATION_CODE,
      }),
      email: PROP_EMAIL,
    }),
  },
);

export const BASE_DATA_DB_SCHEMA = baseDataSchema(false);

export const NOTIFICATION_DB_SCHEMA = SchemaUtil.object(
  ['_id', 'recipient', 'date', 'scope', 'severity', 'subject', 'message'],
  {
    _id: PROP_MONGO_ID,
    cls: SchemaUtil.enum([CLS.USER_NOTIFICATION]),
    recipient: PROP_MONGO_ID,
    projectId: PROP_MONGO_ID,
    date: SchemaUtil.date(),
    readDate: SchemaUtil.date(),
    scope: SchemaUtil.enum(NotificationScope),
    severity: SchemaUtil.enum(NotificationSeverity),
    subject: SchemaUtil.string(),
    message: SchemaUtil.string({
      minLength: 10,
      maxLength: 1000,
      pattern: PATTERN.TEXT_MULTILINE,
    }),
    link: SchemaUtil.object(['target', 'text'], {
      target: SchemaUtil.string({ maxLength: 1000, pattern: PATTERN.PATH }),
      text: SchemaUtil.string(),
    }),
  },
);

/**
 * @see UserSetting
 */
export const USER_SETTINGS_DB_SCHEMA = SchemaUtil.object(['_id', 'userId', 'key', 'value'], {
  _id: PROP_MONGO_ID,
  userId: PROP_MONGO_ID,
  projectId: PROP_MONGO_ID,
  key: SchemaUtil.enum(SETTINGS_KEYS),
  value: {
    oneOf: [SchemaUtil.string({ maxLength: 1000 }), SchemaUtil.array(CUSTOMER_REQUEST_FILTER_SAVED)],
  },
});
