import { Data, DATA_VALUE_TYPE, InputValue, SelectionData, Value } from '../dtos/index';
import { Create } from '../dtos/cls-creator';

export class DataUtil {
  public static dataToDataValue(input: Data | Data[]): InputValue[] {
    const dataArray = Array.isArray(input) ? input : [input];
    return dataArray
      .map((data) => {
        if (!data?.value?.value) {
          return undefined;
        }
        return Create.inputValue(data.value.type, data.value.value);
      })
      .filter((data) => data !== undefined) as InputValue[];
  }

  public static dataValueToArray(input: unknown | unknown[]): unknown[] {
    return (Array.isArray(input) ? input : [input])
      .filter((value) => value !== undefined)
      .filter((value) => value !== null && value !== undefined);
  }

  public static selectionDataValues(selectionData: SelectionData[]): Value[] {
    return selectionData.map((entry) => this.selectionDataValue(entry));
  }

  /**
   * returns the value of the selectionData with the given property
   * or the label as value if no property is given
   */
  public static selectionDataValue(selectionData: SelectionData, property?: string): InputValue {
    if (!property) {
      return Create.inputValue(DATA_VALUE_TYPE.STRING, selectionData.label);
    }
    const value = selectionData.values?.find((value) => value.identifier === property);
    if (value) {
      return value;
    }
    return Create.emptyInputValue();
  }
}
