import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { BreakpointObserver } from '@angular/cdk/layout';
import { map } from 'rxjs/operators';
import { LOCAL_CODE_CONFIG, LocalCodes, LocalConfig } from '@kfd/core';

export enum ScreenSize {
  XS = 'xs',
  MD = 'md',
  LG = 'lg',
}

/**
 * Contains system (non-user specific) configuration options
 */
@Injectable({
  providedIn: 'root',
})
export class CfgSettingsService {
  constructor(private readonly breakpointObserver?: BreakpointObserver) {
    // this.isMinTablet$ = this.breakpointObserver.observe('(min-width: 768px)');
  }

  private _embedded = false;

  get embedded(): boolean {
    return this._embedded;
  }

  set embedded(value: boolean) {
    this._embedded = value;
  }

  private _privacyLink: string | undefined;

  get privacyLink(): string | undefined {
    return this._privacyLink;
  }

  set privacyLink(value: string | undefined) {
    this._privacyLink = value;
  }

  private _skipPrivacy: boolean | undefined;

  get skipPrivacy(): boolean | undefined {
    return this._skipPrivacy;
  }

  /**
   * Skip privacy dialog (only for internal usage)
   */
  set skipPrivacy(value: boolean | undefined) {
    this._skipPrivacy = value;
  }

  private _demoMode: boolean | undefined;

  get demoMode(): boolean | undefined {
    return this._demoMode;
  }

  /**
   * Enables demo mode which disables certain features
   */
  set demoMode(value: boolean | undefined) {
    this._demoMode = value;
  }

  private _noSubmit: boolean | undefined;

  get noSubmit(): boolean | undefined {
    return this._noSubmit;
  }

  /**
   * Skip privacy dialog (only for internal usage)
   */
  set noSubmit(value: boolean | undefined) {
    this._noSubmit = value;
  }

  /**
   * Sets the screen size manually and disables responsiveness
   */
  private _screenSize: ScreenSize | undefined;

  set screenSize(value: ScreenSize | undefined) {
    this._screenSize = value;
  }

  onScreenSizeChange(): Observable<ScreenSize> {
    if (this._screenSize) {
      return of(this._screenSize);
    }
    if (this.breakpointObserver) {
      return this.breakpointObserver.observe(['(min-width: 768px)', '(min-width: 1024px)']).pipe(
        map((result) => {
          if (result.breakpoints['(min-width: 1024px)']) {
            return ScreenSize.MD;
          }
          if (result.breakpoints['(min-width: 768px)']) {
            return ScreenSize.MD;
          }
          return ScreenSize.XS;
        }),
      );
    }
    return of(ScreenSize.XS);
  }

  public localConfig(): LocalConfig {
    return LOCAL_CODE_CONFIG[this.locale()];
  }

  private locale(): string {
    // if (this.configuration?.settings?.l10n?.locale) {
    //   return this.configuration?.settings?.l10n?.locale;
    // }
    switch (navigator.language) {
      case 'de-DE':
        return LocalCodes.DE_DE;
      case 'en-US':
        return LocalCodes.EN_US;
      default:
        return LocalCodes.DE_DE;
    }
  }
}
