// export const CFG_EMPTY: Configuration | undefined = undefined;
// export function empty_configuration(): unknown {
//   return CfgBuilder.create()
// }

//   .addPage(
//     CfgPageBuilder.create({
//       name: 'seite_1',
//       label: 'Seite 1',
//     }),
//   )
//   .build();

// export const CFG_CONTACT: Configuration = CfgBuilder.create()
//   .addPage(
//     CfgPageBuilder.create({
//       name: 'seite_1',
//       label: 'Anfrage',
//     }).addField(
//       CfgFieldBuilder.create({
//         label: 'Name',
//         config: Create.textFieldConfig(),
//       }),
//     ),
//   )
//   .build();
//

import { SelectionData } from './cfg.dto';
import { CLS, DATA_TYPES } from './common.dto';

export enum CONFIGURATION_TEMPLATES {
  CFG_EMPTY = 'cfg-empty',
  CFG_CONTACT = 'cfg-contact',
}

export const TEMPLATE_LIST: SelectionData[] = [
  {
    cls: CLS.SELECTION_DATA,
    type: DATA_TYPES.SELECTION,
    label: 'Leeres Formular',
    description: 'Neues Formular ohne Felder',
    name: CONFIGURATION_TEMPLATES.CFG_EMPTY,
  },
  {
    cls: CLS.SELECTION_DATA,
    type: DATA_TYPES.SELECTION,
    label: 'Kontakformular',
    description: 'Einfaches Kontaktformular',
    name: CONFIGURATION_TEMPLATES.CFG_CONTACT,
  },
];
