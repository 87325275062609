import { ApiClientErrors } from '@kfd/core';

export class HttpError extends Error {}

export class UnauthorizedError extends HttpError {}

export class ForbiddenError extends HttpError {}

export class NotFoundError extends HttpError {}

export class ClientCodeError extends HttpError {
  constructor(
    public override message = '',
    public code: ApiClientErrors,
  ) {
    super();
  }
}

export class UnknownError extends HttpError {
  constructor(
    public override message = '',
    public code = '',
  ) {
    super();
  }
}
