<i *ngIf="infoIconVisible === true" class="icon pi {{ infoIcon }}"></i>

@if (infoTitle) {
  <p class="text-gray-800 text-center mt-2">{{ infoTitle }}</p>
}

@if (infoText) {
  <p class="text-gray-600 text-center text-sm mt-2">{{ infoText }}</p>
}

@if (docuPageId) {
  <kfd-docu-btn [pageId]="docuPageId" [sectionId]="docuSectionId" buttonType="text"></kfd-docu-btn>
}

@if (btnVisible || btnLink) {
  @if (btnLink) {
    <p-button
      [disabled]="btnDisabled"
      [label]="btnLabel"
      [rounded]="true"
      [text]="true"
      [routerLink]="btnLink"
      data-automationId="empty-msg-add-btn"
      class="mt-2"
      icon="pi {{ btnIcon }}"
    ></p-button>
  } @else {
    <p-button
      (click)="btnClick.emit()"
      [disabled]="btnDisabled"
      [label]="btnLabel"
      [rounded]="true"
      [outlined]="true"
      data-automationId="empty-msg-add-btn"
      class="mt-2"
      icon="pi {{ btnIcon }}"
    ></p-button>
  }
}
