import { Directive, ElementRef, HostBinding, Input, OnChanges, OnInit, Renderer2, SimpleChanges } from '@angular/core';

@Directive({
  selector: '[kfdBlock]',
  standalone: false,
})
export class BlockerDirective implements OnInit, OnChanges {
  @HostBinding('style.position')
  hostPosition = 'relative';

  @Input() kfdBlock = false;

  @Input() loader = true;

  @Input() text = '';

  uid = '';

  constructor(
    private targetEl: ElementRef,
    private renderer: Renderer2,
  ) {
    this.uid = 'loading-container-' + Math.random().toString().slice(2);
  }

  ngOnInit() {
    const l2 = this.renderer.createElement('div');
    this.renderer.setAttribute(l2, 'class', 'animation');
    const l1 = this.renderer.createElement('div');
    this.renderer.setAttribute(l1, 'class', 'loading-indicator ' + this.uid);
    this.renderer.setAttribute(l1, 'style', `display: none;`);
    this.renderer.appendChild(l1, l2);
    this.renderer.appendChild(this.targetEl.nativeElement, l1);

    if (this.text) {
      const l3Text = this.renderer.createText(this.text);
      const l3TextEl = this.renderer.createElement('div');
      this.renderer.setAttribute(l3TextEl, 'class', 'text');
      this.renderer.appendChild(l3TextEl, l3Text);
      this.renderer.appendChild(l2, l3TextEl);
    }

    if (this.loader === true) {
      const l3 = this.renderer.createElement('div');
      this.renderer.setAttribute(l3, 'class', 'dot-pulse');
      this.renderer.appendChild(l2, l3);
    }

    this.toggleLoadingState();
  }

  ngOnChanges(simpleChanges: SimpleChanges) {
    if (simpleChanges['kfdBlock']) {
      this.toggleLoadingState();
    }
  }

  toggleLoadingState() {
    const container = this.targetEl.nativeElement;
    const div = container.querySelector('.' + this.uid);
    if (div) {
      if (this.kfdBlock) {
        this.targetEl.nativeElement.style = 'position: relative; overflow: hidden;';
      } else {
        this.targetEl.nativeElement.style = '';
      }
      this.renderer.setStyle(div, 'display', this.kfdBlock ? 'flex' : 'none');
    }
  }
}
