@if (fieldConfig) {
  <div class="input-wrapper">
    <p-inputNumber
      #numberInputField
      (ngModelChange)="onInputValueChange(numberInputField.value)"
      (onClear)="onInputValueChange()"
      [disabled]="fieldConfig.disabled === true"
      [locale]="localConfig.code"
      [maxFractionDigits]="fieldConfig.digits ?? 0"
      [max]="fieldConfig!.max ? fieldConfig!.max!.value : undefined"
      [minFractionDigits]="fieldConfig.digits ?? 0"
      [min]="fieldConfig!.min!.value"
      [ngModel]="inputFieldValue"
      [showButtons]="fieldConfig.showButtons ?? false"
      [step]="fieldConfig!.step ?? 1"
      [suffix]="fieldConfig.unit"
      [useGrouping]="false"
      buttonLayout="horizontal"
      decrementButtonIcon="pi {{ CFG_ICON.MINUS }}"
      incrementButtonIcon="pi {{ CFG_ICON.PLUS }}"
      mode="decimal"
    >
    </p-inputNumber>
  </div>
}
