import {
  calculation,
  CALCULATION_REF,
  conditionalEntrySchema,
  conditionGroup,
  CONFIGURATION_SHORT_CODE,
  DATA_VALUE,
  dynamicDataHandlerSchema,
  FIELD_REF,
  genericProps,
  INFO,
  JsonSchema,
  listDataHandlerSchema,
  PAGE_REF,
  PATTERN,
  PROP_NAME,
  PROP_UUID_OR_NAME,
  PROP_UUID_OR_OBJECT_ID,
  PUBLISHED_CMS_VERSIONS,
  PUBLISHED_PUBLIC_VERSION,
  RELATIVE_DATE,
  SAVE_TEXT,
  SchemaUtil,
  TYPES,
} from './';
import {
  CLS,
  DATA_VALUE_TYPE,
  FIELD_TYPES,
  FORM_VALUE_TYPES,
  SELECT_FIELD_LAYOUTS,
  TEXTFIELD_VALIDATIONS,
} from '../dtos';

/**
 * @see CmsConfigurationEntryMeta
 */
const CFG_META_SCHEMA = SchemaUtil.object(['created', 'createdBy', 'projectId', 'entryId', 'configuratorId'], {
  created: SchemaUtil.date(),
  createdBy: SchemaUtil.objectId(),
  updated: SchemaUtil.date(),
  updatedBy: SchemaUtil.objectId(),
  projectId: SchemaUtil.objectId(),
  entryId: PROP_UUID_OR_OBJECT_ID,
  configuratorId: PROP_UUID_OR_OBJECT_ID,
  version: SchemaUtil.numeric(),
});

function generic_entry_required(published = false) {
  return published ? ['cls', 'name', 'label'] : ['_id', 'cls', 'name', 'label', 'meta'];
}

function generic_entry_properties(published = false) {
  const props = {
    ...genericProps(published),
    meta: CFG_META_SCHEMA,
  };

  if (published) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    delete props._id;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    delete props.meta;
  }
  return props;
}

// using a generic config with all fields to allow keeping values from old configs by switching the type
export const FIELD_CONFIG = {
  bsonType: 'object',
  additionalProperties: false,
  required: ['cls', 'type'],
  properties: {
    cls: {
      enum: [CLS.FIELD_CONFIG],
    },
    type: SchemaUtil.enum(FIELD_TYPES),
    valueType: SchemaUtil.enum(FORM_VALUE_TYPES),
    dataType: SchemaUtil.enum(DATA_VALUE_TYPE),
    value: {
      anyOf: [
        DATA_VALUE,
        // deprecated (replaced by calculation reference)
        calculation(),
        FIELD_REF,
        CALCULATION_REF,
      ],
    },
    labels: SchemaUtil.object(undefined, {
      yes: SchemaUtil.string({}),
      no: SchemaUtil.string({}),
    }),
    unit: SchemaUtil.string({ maxLength: 15 }),
    default: {
      anyOf: [DATA_VALUE, RELATIVE_DATE],
    },
    required: SchemaUtil.bool(),
    disabled: SchemaUtil.bool(),
    min: {
      anyOf: [DATA_VALUE, RELATIVE_DATE],
    },
    max: {
      anyOf: [DATA_VALUE, RELATIVE_DATE],
    },
    layout: SchemaUtil.object(undefined, {
      type: SchemaUtil.enum(SELECT_FIELD_LAYOUTS),
      showDescription: SchemaUtil.bool(),
      showImage: SchemaUtil.bool(),
    }),
    //select
    dataHandler: {
      oneOf: [dynamicDataHandlerSchema(), listDataHandlerSchema()],
    },
    emptySelection: SchemaUtil.string({}),
    additionalValues: SchemaUtil.array(
      SchemaUtil.object(['label', 'value'], {
        label: SchemaUtil.string({}),
        value: PROP_NAME,
      }),
    ),
    //text
    validation: SchemaUtil.enum(TEXTFIELD_VALIDATIONS),
    multiline: SchemaUtil.bool(),
    //date
    disabledDays: SchemaUtil.array(SchemaUtil.numeric()),
    inline: SchemaUtil.bool(),
    showOnFocus: SchemaUtil.bool(),
    showIcon: SchemaUtil.bool(),
    //submit btn
    btnLabel: SchemaUtil.string({}),
    successText: SchemaUtil.string({ maxLength: 300 }),
    showRequestId: SchemaUtil.bool(),
    linkRequestId: SchemaUtil.bool(),
    resetOnSubmit: SchemaUtil.bool(),
    goToPage: PAGE_REF,
    //numeric
    digits: SchemaUtil.int(),
    step: SchemaUtil.numeric(),
    showButtons: SchemaUtil.bool(),
  },
};

export function cfgConfigurationSchema(nested = false, published = false): JsonSchema {
  const schema = {
    bsonType: 'object',
    additionalProperties: false,
    required: [...generic_entry_required(published), 'children', 'settings', 'versions'],
    properties: {
      ...generic_entry_properties(published),
      cls: {
        enum: [CLS.CONFIGURATION],
      },
      versions: published ? PUBLISHED_PUBLIC_VERSION : PUBLISHED_CMS_VERSIONS,
      code: CONFIGURATION_SHORT_CODE,
      description: SchemaUtil.string({
        maxLength: 500,
        pattern: PATTERN.TEXT_MULTILINE,
      }),
      children: SchemaUtil.array(
        nested
          ? conditionalEntrySchema(CLS.PAGE_WRAPPER, cfgPageSchema(true, published))
          : conditionalEntrySchema(CLS.PAGE_WRAPPER),
      ),
      trash: SchemaUtil.date(),
      //public settings
      settings: SchemaUtil.object(['cls'], {
        cls: {
          enum: [CLS.CONFIGURATION_SETTINGS],
        },
        customerEmailField: FIELD_REF,
      }),
      calculations: SchemaUtil.array(
        SchemaUtil.object(['name', 'label', 'calculation'], {
          label: SchemaUtil.string({}),
          name: PROP_UUID_OR_NAME,
          calculation: calculation(),
        }),
      ),
      conditions: SchemaUtil.array(
        SchemaUtil.object(['name', 'label', 'condition'], {
          label: SchemaUtil.string({}),
          name: PROP_UUID_OR_NAME,
          condition: conditionGroup(),
        }),
      ),
      // internal settings
      internalSettings: SchemaUtil.object(undefined, {
        titleFields: SchemaUtil.array({ bsonType: [TYPES.STRING] }),
        initialState: SAVE_TEXT,
      }),
    },
  };

  return schema;
}

export function cfgPageSchema(nested = false, published = false): JsonSchema {
  return {
    bsonType: 'object',
    additionalProperties: false,
    required: [...generic_entry_required(published), 'children'],
    properties: {
      ...generic_entry_properties(published),
      cls: {
        enum: [CLS.PAGE],
      },
      hide: SchemaUtil.bool(),
      hideLabel: SchemaUtil.bool(),
      noNavPrev: {
        bsonType: 'bool',
      },
      noNavNext: {
        bsonType: 'bool',
      },
      children: {
        bsonType: 'array',
        items: nested
          ? conditionalEntrySchema([CLS.FIELD_GROUP_WRAPPER, CLS.FIELD_WRAPPER], {
              bsonType: 'object',
              additionalProperties: true,
              anyOf: [cfgFieldGroupSchema(true, published), cfgFieldSchema(published)],
            } as JsonSchema)
          : conditionalEntrySchema([CLS.FIELD_GROUP_WRAPPER, CLS.FIELD_WRAPPER]),
      },
      info: INFO,
      trash: {
        bsonType: 'date',
      },
    },
  };
}

/**
 * @see FieldGroup
 * @param nested
 * @param published
 */
export function cfgFieldGroupSchema(nested = false, published = false): JsonSchema {
  return {
    bsonType: 'object',
    additionalProperties: false,
    required: [...generic_entry_required(published), 'children'],
    properties: {
      ...generic_entry_properties(published),
      cls: {
        enum: [CLS.FIELD_GROUP],
      },
      hide: SchemaUtil.bool(),
      hideLabel: SchemaUtil.bool(),
      info: INFO,
      hint: SchemaUtil.string({
        maxLength: 300,
      }),
      children: {
        bsonType: 'array',
        items: nested
          ? conditionalEntrySchema(CLS.FIELD_WRAPPER, cfgFieldSchema(published))
          : conditionalEntrySchema(CLS.FIELD_WRAPPER),
      },
    },
  };
}

export function cfgFieldSchema(published = false): JsonSchema {
  return {
    bsonType: 'object',
    additionalProperties: false,
    required: [...generic_entry_required(published), 'config'],
    properties: {
      ...generic_entry_properties(published),
      cls: {
        enum: [CLS.FIELD],
      },
      info: INFO,
      hide: SchemaUtil.bool(),
      hideLabel: SchemaUtil.bool(),
      hint: SchemaUtil.string({
        maxLength: 300,
      }),
      config: FIELD_CONFIG,
    },
  };
}

/**
 * @see CmsConfigurator
 */
export const CONFIGURATION_DB_SCHEMA = {
  bsonType: 'object',
  additionalProperties: true,
  anyOf: [cfgConfigurationSchema(), cfgPageSchema(), cfgFieldGroupSchema(), cfgFieldSchema()],
} as JsonSchema;

/**
 * @see PublishedConfigurationEntry
 */
export const PUBLISHED_CFG_DB_SCHEMA = SchemaUtil.object(
  ['_id', 'projectId', 'configurationId', 'version', 'configuration'],
  {
    _id: SchemaUtil.objectId(),
    projectId: SchemaUtil.objectId(),
    configurationId: SchemaUtil.objectId(),
    version: SchemaUtil.numeric(),
    configuration: cfgConfigurationSchema(true, true),
  },
);
