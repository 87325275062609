import { Component, Input, ViewEncapsulation } from '@angular/core';
import { ICON } from '@kfd/core';

export interface StructuredData {
  automationId?: string;
  label?: string;
  type: 'text' | 'bool' | 'date' | 'object' | 'array' | 'code' | 'number' | 'html' | 'link' | 'url' | 'color';
  hint?: string;
  hidden?: boolean;
}

export interface TextData extends StructuredData {
  type: 'text';
  value: string | unknown;
}

export interface DateData extends StructuredData {
  type: 'date';
  format?: 'short' | 'long' | 'relative' | 'relative-short' | 'relative-long';
  value: string | Date;
}

export interface BoolData extends StructuredData {
  type: 'bool';
  value: number | string | boolean;
}

export interface NumericData extends StructuredData {
  type: 'number';
  value: number;
}

export interface UrlData extends StructuredData {
  type: 'url';
  value: string;
}

export interface LinkData extends StructuredData {
  type: 'link';
  target: string;
  text: string;
}

export interface CodeData extends StructuredData {
  type: 'code';
  value: string;
}

export interface ObjectData extends StructuredData {
  type: 'object';
  value: unknown;
}

export interface ArrayData extends StructuredData {
  type: 'array';
  value: Record<string, unknown>[];
  labelField: string;
  valueField: string;
}

export interface ColorData extends StructuredData {
  type: 'color';
  value: string;
}

export type ListDataValue =
  | TextData
  | BoolData
  | DateData
  | NumericData
  | LinkData
  | UrlData
  | CodeData
  | ObjectData
  | ArrayData
  | ColorData;
export type ListData = Record<string, ListDataValue>;

@Component({
  selector: 'kfd-key-value-list',
  templateUrl: './key-value-list.component.html',
  styleUrls: ['./key-value-list.component.scss'],
  encapsulation: ViewEncapsulation.None,
  standalone: false,
})
export class KeyValueListComponent {
  protected readonly ICON = ICON;
  private listData: ListData | undefined;

  get data(): ListData | undefined {
    return this.listData;
  }

  @Input()
  set data(listData: ListData) {
    for (const [, entry] of Object.entries(listData)) {
      if (this.isStructuredData(entry)) {
        continue;
      }
      const structuredData = entry as unknown as StructuredData;
      switch (structuredData.type) {
        case 'url':
          try {
            (structuredData as UrlData).value = decodeURIComponent((structuredData as UrlData).value);
          } catch (_) {
            //do nothing
          }
          break;
        case 'object':
          (structuredData as ObjectData).value = JSON.stringify((structuredData as ObjectData).value);
          break;
      }
    }
    this.listData = listData;
  }

  unsorted(): number {
    return 0;
  }

  private isStructuredData(obj: unknown): obj is StructuredData {
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions,@typescript-eslint/no-unnecessary-condition
    return (obj as StructuredData)?.type === undefined;
  }
}
