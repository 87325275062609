import { Component, Input } from '@angular/core';
import { VALIDATION_ERROR_DETAILS, VALIDATION_ERROR_TYPE, ValidationMap, ValidationResult } from '@kfd/core';
import { ConfigurationService } from '../../service/configuration.service';

const TYPE_TO_SEVERITY: Record<string, string> = {};
TYPE_TO_SEVERITY[VALIDATION_ERROR_TYPE.REQUIRED] = 'warn';
TYPE_TO_SEVERITY[VALIDATION_ERROR_TYPE.INVALID] = 'error';

@Component({
  selector: 'kfd-validation-error',
  templateUrl: './validation-error.component.html',
  styleUrls: ['./validation-error.component.scss'],
  standalone: false,
})
export class ValidationErrorComponent {
  invalidFields: string[] = [];
  type = VALIDATION_ERROR_TYPE;
  detail = VALIDATION_ERROR_DETAILS;

  constructor(private _cfgService: ConfigurationService) {}

  private _validationErrors: ValidationMap = new Map<string, ValidationResult>();

  get validationErrors(): ValidationMap {
    return this._validationErrors;
  }

  @Input()
  set validationErrors(value: ValidationMap) {
    this._validationErrors = value;
    this.invalidFields = [];

    for (const [fieldName, validationResult] of this._validationErrors.entries()) {
      if (validationResult === undefined) {
        continue;
      }
      const field = this._cfgService.cfgUtil.getFieldByName(fieldName);
      if (!field) {
        continue;
      }
      // this.messages.push({
      //   severity: error.type ? TYPE_TO_SEVERITY[error.type] : 'info',
      //   summary: field.entry.label,
      //   detail: error.detail,
      // });
      this.invalidFields.push(field.label ? field.label : field.name);
    }
  }
}
