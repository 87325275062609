import { Component, Input } from '@angular/core';

@Component({
  selector: 'kfd-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss'],
  standalone: true,
})
export class LoaderComponent {
  @Input()
  public message: string | undefined;
}
